import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { Switch, Select, Button, BackTop, Tag, Divider, message } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  getBuildingData,
  getMarketData,
  getSubMarketData,
  getNearbyBuildingData,
  setRetainedValue,
} from '../../store/actions/buildings.actions'
import InfiniteScroll from 'react-infinite-scroll-component'

import { toastMessage } from '../../utils/utils'

import SideMenu from '../../component/SideMenu'
import Header from '../../component/Header'
import Search from '../../component/Search'
import Footer from '../../component/Footer'

import nopath from '../../assets/img/NoPath.png'
import direction from '../../assets/img/direction.svg'
import chevRight from "../../assets/img/chevRight.svg";

import Map from '../../component/Map'

const { Option } = Select

const SearchPage = ({ history, logout, loading }) => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  let { state } = useLocation()

  const {
    searchBuildingData,
    subMarketDetails,
    marketDetails,
    distance,
    retainedValue,
  } = useSelector((state) => state.building)
  const { profile } = useSelector((state) => state.user)

  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState('Open')
  const [marketValue, setMarket] = useState('Select Market')
  const [subMarketValue, setSubMarket] = useState('Select Sub Market')
  const [locationSearchResult, setLocationSearchResult] = useState(false)
  const [marketSearchResult, setMarketSearchResult] = useState(false)
  const [subMarketSearchResult, setSubMarketSearchResult] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(false)
  const [allData, setAllData] = useState([])
  const [selectCustomer, setSelectCustomer] = useState([])
  const [disable, setDisable] = useState('');

  const [hasMore, setHasmore] = useState(true)
  const [lastPosition, setLastPosition] = useState(0)
  const perPage = 10

  useEffect(() => {

    if (retainedValue) {
      dispatch(getBuildingData({ searchData: retainedValue }))
    }
    if (_isEmpty(state) && !_isEmpty(profile) && _isEmpty(retainedValue)) {
      dispatch(
        getBuildingData({
          marketArray: profile.marketDetails.map((i) => {
            return i
          }),
        }),
      )
    }
    if (!_isEmpty(state) && state.hasOwnProperty('coords')) {
      dispatch(
        getNearbyBuildingData(
          { lat: state.coords.lat, log: state.coords.log },
          false,
        ),
      )
      setLocationSearchResult(true)
    }
    if (state && state.hasOwnProperty('searchVal'))
      setSearchValue(state.searchVal)
    navigate({ state: {} })
  }, [])

  useEffect(() => {
    if (!_isEmpty(profile)) {
      dispatch(
        getMarketData({ region_name: `"${[...new Set(profile.region)]}"` }),
      )
    }
  }, [profile])

  useEffect(() => {
    setAllData(searchBuildingData.slice(0, 10))
  }, [searchBuildingData])

  useEffect(() => {
    setSearchValue(retainedValue)
  }, [retainedValue])

  const loadMoreData = () => {
    if (allData.length === searchBuildingData.length) {
      setHasmore(false)
      return
    }

    setTimeout(() => {
      setAllData(searchBuildingData.slice(0, allData.length + 10))
    }, 2000)

    setLastPosition(lastPosition + perPage)
  }

  const handleCallback = (callbackValue) => {
    // setSearchValue(callbackValue);
    setMarket('Select Market')
    setSubMarket('Select Sub Market')
    setOpen('Open')
    setLocationSearchResult(false)
    setSwitchChecked(false)
    const location = document.getElementById('loaction-toggle')
    const searchDiv = document.getElementById('market-serch-box')
    const marketSearch = document.getElementById('market-search')
    location.style.display = 'block'
    searchDiv.style.display = 'none'
    marketSearch.style.display = 'block'
  }

  const getBuildingPageData = (e, ev) => {
    if (ev.target.id !== 'schedulerSelect') {
      localStorage.setItem(
        'bd',
        JSON.stringify(
          e.property_code ? e.property_code : e.propertInfo.propertCode,
        ),
      )
      navigate('/buildingInfo')
    }

    if (ev.target.id === 'schedulerSelect') {
      setDisable(e.propertInfo.propertCode)
      clearSelection()
    }

  }

  const advancesearch = (e) => {
    if (e === 'market') {
      if (open === 'Open') {
        setOpen('Close')
      } else {
        setOpen('Open')
        setMarket('Select Market')
        setSubMarket('Select Sub Market')
      }

      const location = document.getElementById('loaction-toggle')
      const searchDiv = document.getElementById('market-serch-box')
      if (location.style.display !== 'none') {
        location.style.display = 'none'
        searchDiv.style.display = 'block'
      } else {
        location.style.display = 'block'
        searchDiv.style.display = 'none'
      }
    }

    if (e === 'location') {
      const location = document.getElementById('market-search')
      if (location.style.display !== 'none') {
        location.style.display = 'none'
      } else {
        location.style.display = 'block'
      }
    }
  }

  const locationSearch = (e) => {
    if (e) {
      setSwitchChecked(true)
      setMarketSearchResult(false)
      setSubMarketSearchResult(false)
      dispatch(setRetainedValue(''))
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          dispatch(
            getNearbyBuildingData(
              { lat: position.coords.latitude, log: position.coords.longitude },
              false,
            ),
          )
          setLocationSearchResult(true)
        })
      } else {
        toastMessage('info', 'Geolocation is not supported by this browser')
      }
    } else {
      setLocationSearchResult(false)
      setSwitchChecked(false)
      setMarketSearchResult(false)
      setSubMarketSearchResult(false)
      dispatch(
        getBuildingData({
          marketArray: profile.marketDetails.map((i) => {
            return i
          }),
        }),
      )
    }
  }

  const searchMarket = () => {
    if (marketValue === 'Select Market')
      return toastMessage('info', 'Please select the Market')

    const searchData = document.getElementById('searchBoxVal').value

    if (
      searchData.trim() &&
      marketValue !== 'Select Market' &&
      subMarketValue !== 'Select Sub Market'
    )
      dispatch(
        getBuildingData({
          searchData: searchData,
          market_name: marketValue,
          submarket_name: subMarketValue,
        }),
      )
    else if (subMarketValue && subMarketValue !== 'Select Sub Market')
      dispatch(
        getBuildingData({
          market_name: marketValue,
          submarket_name: subMarketValue,
        }),
      )
    else if (searchData.trim() && marketValue !== 'Select Market')
      dispatch(
        getBuildingData({ searchData: searchData, market_name: marketValue }),
      )
    else if (marketValue !== 'Select Market')
      dispatch(getBuildingData({ market_name: marketValue }))
    setSearchValue('')
    setMarketSearchResult(true)
    if (subMarketValue !== 'Select Sub Market') setSubMarketSearchResult(true)
  }

  const selectCustomerMeth = (e, i) => {
    let list = {
      "schedulerName": profile.displayName,
      "scheduleDateTime": null,
      "meetingStartTime": null,
      "meetingEndTime": '',
      "schedulerCoordinater": '',
      "buildingId": i.propertInfo.propertCode,
      "customerId": e.customerId,
      "leaseId": e.buildingUnitCharacteristics.leaseInfo.leaseCode,
      "customerName": e.buildingUnitCharacteristics.leaseInfo.leaseName,
      "customerContact": '',
      "unitCode": e.buildingUnitCharacteristics.unitCode,
      "tag": '',
      buildingName: i.buildingName,
      buildingAddress: i.buildingAdress.fullAdress,
    }

    if (selectCustomer.some(i => i.customerId === list.customerId)) {
      setSelectCustomer(selectCustomer.filter(i => i.customerId !== list.customerId));
    } else {
      setSelectCustomer(selectCustomer => [...selectCustomer, list]);
    }
  }

  const clearSelection = () => {
    const nodelist = document.querySelectorAll("input");
    for (let i = 0; i < nodelist.length; i++) {
      nodelist[i].checked = false;
    }
    setSelectCustomer([]);
  };

  const addToScheduler = () => {
    if (selectCustomer.length > 0) {
      navigate('/planner&scheduler', { state: { selectCustomer: selectCustomer } })
    } else {
      message.info('Please select a customer.')
    }
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="sidenav m-hide">
          <SideMenu />
        </div>
        <div className="main pb-100">
          <div className="row">
            <Header title="Search" />
          </div>
          <div style={{ height: `calc(100% - 5px)` }}>
            <BackTop />
            <div className="row">
              <div className="container-search text-center z-index">
                <Search callback={handleCallback} />
                <div
                  id="loaction-toggle"
                  className="form-check form-switch float-start cursor advance-search-button mt-15"
                >
                  <Switch
                    checkedChildren="Yes"
                    checked={switchChecked}
                    unCheckedChildren="No"
                    onChange={() => advancesearch('location')}
                    onClick={locationSearch}
                  />
                  <label className="form-check-label">
                    {' '}
                    &nbsp;&nbsp;Device location based search
                  </label>
                </div>
                <label
                  id="market-search"
                  className="float-end cursor advance-search-button mt-15 mr-10"
                  onClick={() => advancesearch('market')}
                >
                  {open} Advance Search
                </label>
              </div>
              <div
                id="market-serch-box"
                className="container-search back-trans pt-0 mt-n display-none"
              >
                <div className="market-search ml-30 mr-30 border-radius pd-20">
                  <div className="row">
                    <div className="col-4">
                      <label className="market-search-label">Market*</label>
                      <br></br>
                      <Select
                        showSearch
                        autoClearSearchValue="false"
                        className="cursor"
                        style={{
                          width: 250,
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        value={marketValue}
                        onChange={(e) => {
                          e != '0' ? setMarket(e) : setMarket('Select Market')
                        }}
                        onSelect={(e) => {
                          dispatch(
                            getSubMarketData({
                              region_name: '"AM-Central"',
                              market_name: `"${e}"`,
                            }),
                          )
                          setSubMarket('Select Sub Market')
                          setMarketSearchResult(false)
                          setSubMarketSearchResult(false)
                        }}
                      >
                        {!_isEmpty(marketDetails)
                          ? marketDetails.map((e, i) => {
                            return (
                              <Option key={`market-option${i}`} value={e}>
                                {e}
                              </Option>
                            )
                          })
                          : ''}
                      </Select>
                    </div>
                    <div className="col-4">
                      <label className="market-search-label">Sub Market</label>
                      <br></br>
                      <Select
                        id="sub-market-select"
                        showSearch
                        className="cursor"
                        style={{
                          width: 250,
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        value={subMarketValue}
                        onChange={(e) => {
                          e != '0'
                            ? setSubMarket(e)
                            : setSubMarket('Select Sub Market')
                        }}
                        onSelect={(e) => {
                          setSubMarketSearchResult(false)
                        }}
                      >
                        {!_isEmpty(subMarketDetails)
                          ? subMarketDetails.map((e, i) => {
                            return (
                              <Option key={`sub-market-option${i}`} value={e}>
                                {e ? e[0].toUpperCase() + e.substring(1) : ''}
                              </Option>
                            )
                          })
                          : ''}
                      </Select>
                    </div>
                    <div className="col-4">
                      <label className="market-search-label"></label>
                      <br></br>
                      <Button
                        type="primary"
                        className="btn btn-primary float-end button-pri"
                        htmlType="submit"
                        onClick={searchMarket}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <label className="search-title lable-margin-top">
                {searchValue &&
                  marketValue === 'Select Market' &&
                  !locationSearchResult
                  ? `Showing ${allData.length}/${searchBuildingData.length} results for "${searchValue}"`
                  : marketValue !== 'Select Market'
                    ? `Advance search results ${marketSearchResult ? `for "${marketValue}"` : ''
                    }${subMarketSearchResult
                      ? ` > "${subMarketValue[0].toUpperCase() +
                      subMarketValue.substring(1)
                      }"`
                      : ''
                    } showing ${allData.length}/${searchBuildingData.length} `
                    : locationSearchResult && distance
                      ? `${allData.length}/${searchBuildingData.length} buildings within ${distance}`
                      : `Showing ${allData.length}/${searchBuildingData.length}`}
              </label>
              {!_isEmpty(searchBuildingData) && !locationSearchResult ?
                <Tag
                  color="#2C728A"
                  className='float-end mt-10 cursor'
                  data-bs-toggle="modal"
                  data-bs-target="#mapView">Map View</Tag>
                : ''}
            </div>

            {allData.length > 0 ? (
              <InfiniteScroll
                dataLength={allData.length}
                next={loadMoreData}
                hasMore={hasMore}
                loader={<h6>Loading...</h6>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>No More Data</b>
                  </p>
                }
                style={{ overflow: 'hidden' }}
              >
                {allData.map((i, e) => {
                  return (
                    <div
                      className="row"
                      key={`${e}-search-grid`}
                    >
                      <div className="col">
                        <div className="row search-title-row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" onClick={(e) => getBuildingPageData(i, e)}>
                            <div className="row">
                              <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                <img src={nopath} alt="ContactIcon" className='w-100' />
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label className="title">
                                  {i.buildingName
                                    ? i.buildingName
                                    : i.building_name}
                                </label>
                                {'  '}
                                <Tag color="default">
                                  {i.propertInfo
                                    ? i.propertInfo.propertCode
                                    : i.property_code}
                                </Tag>
                                <br></br>
                                {i.buildingAdress ? (
                                  <p className="description">
                                    {i.buildingAdress.fullAdress}
                                  </p>
                                ) : (
                                  <p className="description">
                                    {i.building_full_address_text}
                                  </p>
                                )}
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                <div className="col m-top">
                                  <label className="title">
                                    Customers in this building
                                  </label>
                                  <br></br>
                                  <div className="card-para">
                                    {i.buildingTenantContactDetails
                                      ? i.buildingTenantContactDetails.map(
                                        (x, y) => {
                                          return x.customerName ? (
                                            <Tag
                                              color="cyan"
                                              key={`cust-${y}`}
                                              className="mb-2"
                                            >
                                              {x.buildingUnitCharacteristics.leaseInfo.leaseName}
                                            </Tag>
                                          ) : (
                                            ''
                                          )
                                        },
                                      )
                                      : i.customers
                                        ? i.customers.map((x, y) => {
                                          return x.customer_name ? (
                                            <Tag
                                              color="cyan"
                                              key={`cust-${y}`}
                                              className="mb-2"
                                            >
                                              {x.customer_name}
                                            </Tag>
                                          ) : (
                                            ''
                                          )
                                        })
                                        : ''}
                                  </div>
                                </div>
                                {!locationSearchResult ?
                                  <div id="schedulerSelect" className='scheduler-div'>
                                    <label id="schedulerSelect" className='cursor title color-blue'>Add to Scheduler</label>
                                    {disable === i.propertInfo.propertCode ?
                                      <CaretUpOutlined id="schedulerSelect" className='float-end' />
                                      :
                                      <CaretDownOutlined id="schedulerSelect" className='float-end' />
                                    }
                                  </div>
                                  : ''}
                              </div>
                            </div>
                          </div>
                          {i.distance >= 0 ? (
                            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-2 m-top">
                              <img
                                src={direction}
                                className="bblr w-15"
                                alt="direction"
                              />
                              <label className="grid-title ml-10">
                                {i.distance === 0
                                  ? '0 Mi'
                                  : i.distance
                                    ? i.distance.toFixed(2) + ' Mi'
                                    : ''}
                              </label>
                            </div>
                          ) : (
                            ''
                          )}
                          {!locationSearchResult ?
                            <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 ${disable === i.propertInfo.propertCode ? "display-block" : "display-none"}`} >
                              <Divider className='search-divider' />
                              {i.buildingTenantContactDetails
                                ? i.buildingTenantContactDetails.map(
                                  (x, y) => {
                                    return x.customerName ? (
                                      <div className="form-check form-check-inline" key={`customer-select${y}`}>
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value={x.buildingUnitCharacteristics.leaseInfo.leaseName} onChange={() => selectCustomerMeth(x, i)} />
                                        <label className="form-check-label title">{x.buildingUnitCharacteristics.leaseInfo.leaseName}</label>
                                        <p className="form-check-label pop-key">{"Unit: "}{x.buildingUnitCharacteristics.unitCode}</p>
                                      </div>
                                    ) : (
                                      ''
                                    )
                                  },
                                )
                                : i.customers
                                  ? i.customers.map((x, y) => {
                                    return x.customer_name ? (
                                      <div className="form-check form-check-inline" key={`customer-select${y}`}>
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value={x.customer_name} />
                                        <label className="form-check-label title">{x.customer_name}</label>
                                      </div>
                                    ) : (
                                      ''
                                    )
                                  })
                                  : ''}
                              <div className='button-container text-right'>
                                <button type="button" className="btn btn-outline-secondary float-right mr-10" onClick={clearSelection}>Clear Selection</button>
                                <button type="button" className="btn btn-primary float-right" onClick={addToScheduler}>Add to Scheduler</button>
                              </div>
                            </div>
                            : ''}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </InfiniteScroll>
            ) : (
              <div className="container-fluid">
                <label className="text-normal">No Data Available</label>
              </div>
            )}
          </div>
        </div>
        {!_isEmpty(searchBuildingData) && !locationSearchResult ?
          <Map
            id="mapView"
            title={'Buildings'}
            dTitle={'Buildings List'}
            data={searchBuildingData}
            nearByBuildings={searchBuildingData}
          />
          : ''}
        <Footer />
      </div>
    </React.Fragment>
  )
}

SearchPage.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  logout: PropTypes.func,
}

export default SearchPage
