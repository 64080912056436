import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { sendEmailAction } from '../store/actions/pitch.actions'
import { logout } from '../store/actions/user.actions'

import { toastMessage } from '../utils/utils'

function PWAOfflineStatus(props) {
  let [online, isOnline] = useState(navigator.onLine)
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const dispatch = useDispatch()

  const setOnline = () => {
    console.log('We are online!')
    isOnline(true)
    ;(async () => {
      //...
      const request = indexedDB.open('esm')

      request.onsuccess = (event) => {
        const db = event.target.result
        const tx = db.transaction('emails', 'readwrite').objectStore('emails')
        let res = tx.getAll()

        res.onsuccess = (event) => {
          dispatch(sendEmailAction(res.result))
          indexedDB.deleteDatabase('esm')
        }
      }
    })()
  }
  const setOffline = () => {
    console.log('We are offline!')
    isOnline(false)
  }

  useEffect(() => {
    window.addEventListener('offline', setOffline)
    window.addEventListener('online', setOnline)

    return () => {
      window.removeEventListener('offline', setOffline)
      window.removeEventListener('online', setOnline)
    }
  }, [])

  if (navigator.onLine && userInfo) {
    if (new Date().getTime() >= new Date(userInfo.expiresOn).getTime()) {
      toastMessage('info', 'Your session is expired, we are reloading page')
      dispatch(logout())
    }
  }

  return !online ? (
    <>
      <h6 className="pwa-warning">Offline Mode</h6>
      <style jsx>{`
        .pwa-warning {
          position: absolute;
          right: 5px;
          top: 5px;
          background-color: #414141;
          color: #ffffff;
          text-align: center;
          padding: 5px;
        }
      `}</style>
    </>
  ) : null
}

export default PWAOfflineStatus
