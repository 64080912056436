/* eslint-disable no-template-curly-in-string */

export const BASE_URL = window.BASE_URL || "http://localhost:8080/api";
export const UPLOAD_URL = window.UPLOAD_URL || "http://localhost:9109";
export const TOKEN = "";
export const USER = "";

export const ROUTES = {
    HOME: {
        path: "/buildingInfo",
        title: "Buildings HomePage",
        name: "Buildings HomePage",
    },
    PITCHTEMPLATE: {
        path: "/pitch-template",
        title: "Pitch Template Page",
        name: "Pitch Template Page",
    },
    PITCHVIEWER: {
        path: "/pitch-viewer",
        title: "Pitch Viewer Page",
        name: "Pitch Viewer Page",
    },
    SEARCH: {
        path: "/",
        title: "Search Page",
        name: "search Page",
    },
    VISITS: {
        path: "/planner&scheduler",
        title: "Planner and Scheduler Page",
        name: "Planner and Scheduler Page",
    },
    CONTACT: {
        path: "/contacts",
        title: "Contact Page",
        name: "Contact Page",
    },
};