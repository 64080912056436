import ACTIONS from "./types.actions";
import pitchTemplateService from "../../services/pitch.services";
import { to, throwError, toastMessage } from "../../utils/utils";

export function pitchTemplateData(data) {
    return { type: ACTIONS.PITCH_TEMPLATE_DATA, data };
}

export function emailTemplateData(data) {
    return { type: ACTIONS.EMAIL_TEMPLATE_DATA, data };
}

export function templateDataIn(data) {
    return { type: ACTIONS.EMAIL_TEMPLATE_HTML, data };
}

export function emailStatus(data) {
    return { type: ACTIONS.EMAIL_STATUS, data };
}

export function getPitchTemplateData(data) {
    return async dispatch => {
        try {
            const [err, response] = await to(pitchTemplateService.getPitchTemplateData());
            if (err) throwError(err);
            dispatch(pitchTemplateData(response.data.products.items.filter((i) => (i.catalog_link)).concat(response.data.products.items.filter((i) => !(i.catalog_link)))));
        } catch (error) {
            throwError(error);
        }
    };
}

export function sendEmailAction(data) {
    return async dispatch => {
        try {
            const [err, response] = await to(pitchTemplateService.sendEmail(data));
            if (err) throwError(err);
            dispatch(emailStatus(true));
            document.getElementById('cancel').click("toastr.info('Hi! I am info message.')");
            toastMessage("info", response);
        } catch (error) {
            throwError(error);
        }
    };
}

export function getEmailTemplates(data) {
    return async dispatch => {
        try {
            const [err, response] = await to(pitchTemplateService.getemailtemplates(data));
            if (err) throwError(err);
            dispatch(emailTemplateData(response.data.TemplatesMetadata))
        } catch (error) {
            throwError(error);
        }
    };
}

export function getEmailTemplatesData(data) {
    return async dispatch => {
        try {
            const [err, response] = await to(pitchTemplateService.getemailtemplates(data));
            if (err) throwError(err);
            dispatch(templateDataIn(response.data.Template))
        } catch (error) {
            throwError(error);
        }
    };
}