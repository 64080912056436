const appInitialState = {
    building: {
        buildingData: {},
        searchBuildingData: [],
        partnerData: [],
        customerdata: {},
        previousSalesPopup: {},
        nearByBuildings: [],
        marketDetails: [],
        subMarketDetails: [],
        distance: '',
        customerContact: [],
        retainedValue: '',
        autoFillValue: [],
    },
    user: {
        loading: false,
        error: null,
        value: {},
        profile: {},
    },
    pitch: {
        pitchTemplate: [],
        emailTemplate: [],
        templateData: {},
        emailStatus: null,
    },
    visits: {
        visitsData: {},
        upcoming: [],
        past: [],
        scheduledData: [],
        updatedCall: false
    },
};

export default appInitialState;