import _isEmpty from 'lodash/isEmpty'
import { List, Tag } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  CircleMarker,
  useMap,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L, { control } from 'leaflet'

import markerIconPng from "leaflet/dist/images/marker-icon.png"

const Map = (props) => {

  const [position, setPosition] = useState([props.data[0].buildingLocation.lat, props.data[0].buildingLocation.log]);
  const [popupData, setPopupData] = useState(props.data[0]);
  const [map, setMap] = useState(null);

  useEffect(() => {
    setPosition([popupData.buildingLocation.lat, popupData.buildingLocation.log])
  }, [popupData])

  useEffect(() => {
    setPopupData(props.data[0])
  }, [props.title])

  useEffect(() => {
    if (map) {
      setInterval(function () {
        map.target._onResize();
      }, 100);
    }
  }, [map]);

  const getPopupDetails = (e) => {
    return (
      <Popup className="popup-placment" keepInView={true}>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Info
            </button>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-expanded="false"
            >
              Units (
              {e.buildingTenantContactDetails.length}
              )
            </a>
            <ul className="dropdown-menu">
              <li className="nav-item">
                {e.buildingTenantContactDetails
                  .length
                  ? e.buildingTenantContactDetails.map(
                    (i, j) => {
                      return (
                        <button
                          className="nav-link button-font"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target={`#profile-tab${j}`}
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          key={`unit-dropdown-${j}`}
                        >
                          Unit{' '}
                          {i.buildingUnitCharacteristics.unitCode} (
                          {
                            i.buildingMarketHierarchy
                              .statisticalMetricsNetRentableArea
                          }{' '}
                          SF)
                        </button>
                      )
                    },
                  )
                  : ''}
              </li>
            </ul>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="col mapView mt-10">
              <label className="info">
                {e.buildingName}
                {'  '}
                <Tag color="default">
                  {popupData.propertInfo.propertCode}
                </Tag>
              </label>
              <p className="info-address">
                {e.buildingAdress.fullAdress}
              </p>
              <table className="table table-striped w-85">
                <tbody className="info-address">
                  <tr>
                    <td>Region</td>
                    <td>
                      {e.buildingAdress.regionName}
                    </td>
                  </tr>
                  <tr>
                    <td>Sub Region</td>
                    <td>
                      {
                        e.buildingAdress
                          .subRegionName
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {e.buildingTenantContactDetails.length
            ? e.buildingTenantContactDetails.map(
              (i, j) => {
                return (
                  <div
                    className="tab-pane fade"
                    id={`profile-tab${j}`}
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    key={`unit-${j}`}
                  >
                    <div className="col mapView mt-10">
                      <label className="info">
                        Unit -{' '}
                        {i.buildingUnitCharacteristics.unitCode}{' '}
                        <Tag color="default">
                          {
                            e.propertInfo
                              .propertCode
                          }
                        </Tag>
                      </label>
                      <p className="info-address">
                        {
                          e.buildingAdress
                            .fullAdress
                        }
                      </p>
                      <p className="info-address">
                        {
                          i.buildingMarketHierarchy
                            .statisticalMetricsNetRentableArea
                        }
                        {' SF'}
                      </p>
                      <table className="table table-striped w-85">
                        <tbody className="info-address">
                          <tr>
                            <td>Lease Name</td>
                            <td>
                              {
                                i.buildingUnitCharacteristics
                                  .leaseInfo.leaseName
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Lease Type</td>
                            <td>
                              {
                                i.buildingUnitCharacteristics
                                  .leaseInfo.leaseTypeName
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Lease Start Date</td>
                            <td>
                              {
                                i.buildingUnitCharacteristics
                                  .leaseInfo.leaseStartDate
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Lease End Date</td>
                            <td>
                              {
                                i.buildingUnitCharacteristics
                                  .leaseInfo.leaseEndDate
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              },
            )
            : ''}
        </div>
      </Popup>
    )
  }

  return (
    <div
      id="mapView"
      className="modal fade"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ fontSize: '10px' }}
            ></button>
          </div>
          <div className="modal-body bg-fade pd-0">
            {!_isEmpty(props.data) ?
              <MapContainer
                center={position}
                zoom={15}
                scrollWheelZoom={true}
                style={{ height: '100%', width: '80%', position: 'relative' }}
                whenReady={setMap}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <CircleMarker center={position} pathOptions={{ color: 'green' }} radius={5} ref={(ref) => { if (ref) ref.openPopup() }}>
                  {getPopupDetails(popupData)}
                </CircleMarker>

                {props.data.length > 0 ?
                  props.data.map((e, x) => {
                    if (e.buildingLocation.lat !== null && e.buildingLocation.log !== null)
                      return (
                        <Marker position={[e.buildingLocation.lat, e.buildingLocation.log]} icon={new L.Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} key={`marker-${x}`}>
                          {getPopupDetails(e)}
                        </Marker>
                      )
                  })
                  : ''}
              </MapContainer>
              : ''}
            <div className='drawer-box'>
              <List
                itemLayout="horizontal"
                header={<Tag color="#006858" className='tag-heading'>{props.dTitle}</Tag>}
                dataSource={props.nearByBuildings}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<span className='cursor' onClick={() => { props.nearbyBuildingRedirect ? props.nearbyBuildingRedirect(item) : setPopupData(item) }}>{!_isEmpty(item.buildingName) ? item.buildingName : item.building_name}</span>}
                      description={!_isEmpty(item.buildingAdress) ? item.buildingAdress.fullAdress : item.building_full_address_text}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary float-start"
              data-bs-dismiss="modal"
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Map;