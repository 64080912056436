import _isEmpty from "lodash/isEmpty";
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {
    getVisitsData,
} from "../../store/actions/visits.actions";

import calendarIcon from "../../assets/img/calendar.svg";

const { RangePicker } = DatePicker;

function VisitTab(props) {

    const dispatch = useDispatch();
    const [active, setActive] = useState('today');
    const [activePast, setActivePast] = useState('yesterday');
    const [showDatePicker, setDatePicker] = useState('hide');
    const [showPastDatePicker, setPastDatePicker] = useState('hide');
    const [titleFade, setTitleFade] = useState('');
    const [titleFadePast, setTitleFadePast] = useState('');
    const [upcomingDateRange, setUpcomingDateRange] = useState([]);
    const [pastDateRange, setPastDateRange] = useState([]);
    const { visitsData } = useSelector(state => state.visits);

    useEffect(() => {
        dispatch(getVisitsData(
            {
                startDateTime: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                endDateTime: new Date(new Date().setHours(23, 59, 59, 999)).toISOString()
            }
        ));
    }, []);

    const disabledDate = current => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    const filterUpcomingDate = (e) => {
        const d = new Date();
        setDatePicker('hide');
        setUpcomingDateRange([]);
        setTitleFade('');
        if (e.target.innerText === 'TODAY' || e.target.innerText === 'UPCOMING VISITS') {
            setActive('today');
            dispatch(getVisitsData(
                {
                    startDateTime: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                    endDateTime: new Date(new Date().setHours(23, 59, 59, 999)).toISOString()
                }
            ));
        } else if (e.target.innerText === 'TOMORROW') {
            setActive('tomorrow');
            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)
            dispatch(getVisitsData(
                {
                    startDateTime: new Date(tomorrow.setHours(0, 0, 0, 0)).toISOString(),
                    endDateTime: new Date(tomorrow.setHours(23, 59, 59, 999)).toISOString()
                }
            ));
        } else if (e.target.innerText === 'THIS WEEK') {
            setActive('thisWeek');
            const endDate = new Date(d.setDate(d.getDate() - d.getDay() + 7));
            dispatch(getVisitsData(
                {
                    startDateTime: new Date().toISOString(),
                    endDateTime: new Date(endDate.setHours(23, 59, 59, 999)).toISOString()
                }
            ));
        } else if (e.target.innerText === 'THIS MONTH') {
            setActive('thisMonth');
            const endDate = new Date(new Date(d.getFullYear(), d.getMonth() + 1, 0));
            dispatch(getVisitsData(
                {
                    startDateTime: new Date().toISOString(),
                    endDateTime: new Date(endDate.setHours(23, 59, 59, 999)).toISOString()
                }
            ));
        } else {
            setActive('customeUpcoming');
            setDatePicker('show-flex');
        }
    }

    const filterPastDate = (e) => {
        const d = new Date();
        setPastDatePicker('hide');
        setTitleFadePast('');
        setPastDateRange([]);
        if (e.target.innerText === 'YESTERDAY' || e.target.innerText === 'PAST VISITS') {
            setActivePast('yesterday');
            const yesterday = new Date()
            yesterday.setDate(yesterday.getDate() - 1)
            dispatch(getVisitsData(
                {
                    startDateTime: new Date(yesterday.setHours(0, 0, 0, 0)).toISOString(),
                    endDateTime: new Date(yesterday.setHours(23, 59, 59, 999)).toISOString()
                }
            ));
        } else if (e.target.innerText === 'LAST WEEK') {
            setActivePast('lastWeek');
            const startDate = new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() - 1) - 7));
            const endDate = new Date().setTime(new Date().getTime() - (new Date().getDay() ? new Date().getDay() : 7) * 24 * 60 * 60 * 1000);
            dispatch(getVisitsData(
                {
                    startDateTime: new Date(startDate.setHours(0, 0, 0, 0)).toISOString(),
                    endDateTime: new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString()
                }
            ));
        } else if (e.target.innerText === 'LAST MONTH') {
            setActivePast('lastMonth');
            const startDate = new Date(d.getFullYear(), d.getMonth() - 1, 1);
            const endDate = new Date(new Date(d.getFullYear(), d.getMonth(), 0));
            dispatch(getVisitsData(
                {
                    startDateTime: new Date(startDate.setHours(0, 0, 0, 0)).toISOString(),
                    endDateTime: new Date(endDate.setHours(23, 59, 59, 999)).toISOString()
                }
            ));
        } else {
            setActivePast('customPastFilter');
            setPastDatePicker('show-flex');
        }
    }

    const setCustomUpcomingDate = (e) => {
        setUpcomingDateRange(e);
        setTitleFade('title-fade');
        dispatch(getVisitsData(
            {
                startDateTime: new Date(e[0]._d.setHours(0, 0, 0, 0)).toISOString(),
                endDateTime: new Date(e[1]._d.setHours(23, 59, 59, 999)).toISOString()
            }
        ));
    };

    const setCustomPastDate = (e) => {
        setPastDateRange(e);
        setTitleFadePast('title-fade');
        dispatch(getVisitsData(
            {
                startDateTime: new Date(e[0]._d.setHours(0, 0, 0, 0)).toISOString(),
                endDateTime: new Date(e[1]._d.setHours(23, 59, 59, 999)).toISOString()
            }
        ));
    };

    const openOutlook = (e) => {
        if (e.target.id !== 'joinMeeting') {
            window.open('https://outlook.office.com/owa/');
        }
    }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link inner-tab-font active" id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming"
                            type="button" role="tab" aria-controls="upcoming" aria-selected="true" onClick={filterUpcomingDate}>
                            UPCOMING VISITS</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link inner-tab-font" id="past-tab" data-bs-toggle="tab" data-bs-target="#past"
                            type="button" role="tab" aria-controls="past" aria-selected="false" onClick={filterPastDate} >
                            PAST VISITS</button>
                    </li>
                </ul>
                <div className="tab-content bg-white" id="myTabContent">
                    <div className="tab-pane fade show active" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                        <div className='container-fluid'>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className='w-10' scope="col">
                                                <span className={`cursor ${titleFade} ${active === 'today' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>TODAY</span>
                                            </th>
                                            <th className='w-10' scope="col">
                                                <span className={`cursor ${titleFade} ${active === 'tomorrow' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>TOMORROW</span>
                                            </th>
                                            <th className='w-10' scope="col">
                                                <span className={`cursor ${titleFade} ${active === 'thisWeek' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>THIS WEEK</span>
                                            </th>
                                            <th className='w-10' scope="col">
                                                <span className={`cursor ${titleFade} ${active === 'thisMonth' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>THIS MONTH</span>
                                            </th>
                                            <th scope="col">
                                                <div className="float-end">
                                                    <div>
                                                        <span className={`float-end cursor ${active === 'customeUpcoming' ? 'pop-value' : ''}`} onClick={filterUpcomingDate}>CUSTOM DATE RANGE <img src={calendarIcon} alt="calendarIcon" /></span>
                                                    </div>
                                                    <div>
                                                        <RangePicker id="datePicker" className={`cursor ${showDatePicker}`} allowClear="true" format="MMM, DD YYYY"
                                                            disabledDate={disabledDate} value={upcomingDateRange} onChange={setCustomUpcomingDate} />
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            {!_isEmpty(visitsData) ?
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Contact Person</th>
                                            <th scope="col">Visit Type</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Date & Time</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visitsData.map((e, i) => (
                                            <tr className="cursor" key={`upcoming-row-${i}`} onClick={openOutlook}>
                                                <th className="text-bold">{e.organizer.emailAddress.name}</th>
                                                <td className="text-normal">
                                                    {e.onlineMeeting && e.onlineMeeting.joinUrl.includes('teams') ? 'Teams Meeting' :
                                                        e.location.displayName.includes('zoom') ? 'Zoom Meeting' : e.location.displayName}
                                                </td>
                                                <td className="pop-value">{e.subject}</td>
                                                <td className="text-normal">
                                                    <label className="text-bold">{new Date(e.start.dateTime).toLocaleDateString('en-US', {
                                                        year: 'numeric', month: 'long', day: 'numeric'
                                                    })}</label><br></br>
                                                    <label>{new Date(e.start.dateTime + 'Z').toLocaleTimeString('UTC')}</label>
                                                </td>
                                                <td className="pop-value w-30">
                                                    {!_isEmpty(e.location.address) ?
                                                        <div>
                                                            <span className="underline">{e.location.address.street}</span><br></br>
                                                            <span className="underline">
                                                                {e.location.address.city},&nbsp;
                                                                {e.location.address.state},&nbsp;
                                                                {e.location.address.countryOrRegion},&nbsp;
                                                                {e.location.address.postalCode}</span><br></br>
                                                        </div>
                                                        :
                                                        <span>
                                                            {e.onlineMeeting ?
                                                                <a id="joinMeeting" href={e.onlineMeeting.joinUrl} className="pop-value underline" target="_blank">Join Invite</a>
                                                                :
                                                                e.location.displayName.includes('zoom') ?
                                                                    <a id="joinMeeting" href={e.location.displayName} className="pop-value underline" target="_blank">Join Invite</a>
                                                                    : ''
                                                            }
                                                        </span>}
                                                </td>
                                                <td className="text-normal">{e.responseStatus ? e.responseStatus[0].toUpperCase() + e.responseStatus.substring(1) : ''}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                :
                                <div className='mt-25'>
                                    <label>No Visits</label>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade" id="past" role="tabpanel" aria-labelledby="past-tab">
                        <div className='container-fluid'>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th className='w-10' scope="col">
                                                <span className={`cursor ${titleFadePast} ${activePast === 'yesterday' ? 'pop-value' : ''}`} onClick={filterPastDate}>YESTERDAY</span>
                                            </th>
                                            <th className='w-10' scope="col">
                                                <span className={`cursor ${titleFadePast} ${activePast === 'lastWeek' ? 'pop-value' : ''}`} onClick={filterPastDate}>LAST WEEK</span>
                                            </th>
                                            <th className='w-10' scope="col">
                                                <span className={`cursor ${titleFadePast} ${activePast === 'lastMonth' ? 'pop-value' : ''}`} onClick={filterPastDate}>LAST MONTH</span>
                                            </th>
                                            <th scope="col">
                                                <div className="float-end">
                                                    <div>
                                                        <span className={`float-end cursor ${activePast === 'customPastFilter' ? 'pop-value' : ''}`} onClick={filterPastDate}>CUSTOM DATE RANGE <img src={calendarIcon} alt="calendarIcon" /></span>
                                                    </div>
                                                    <div>
                                                        <RangePicker id="datePicker" className={`cursor ${showPastDatePicker}`} allowClear="true" format="MMM, DD YYYY"
                                                            defaultValue={[moment("MMM, DD YYYY"), moment("MMM, DD YYYY")]}
                                                            disabled={[false, true]}
                                                            defaultPickerValue="" value={pastDateRange} onChange={setCustomPastDate} />
                                                    </div>
                                                </div>

                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            {!_isEmpty(visitsData) ?
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Contact Person</th>
                                            <th scope="col">Visit Type</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Date & Time</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visitsData.map((e, i) => (
                                            <tr className="cursor" key={`past-row-${i}`} onClick={openOutlook}>
                                                <th className="text-bold">{e.organizer.emailAddress.name}</th>
                                                <td className="text-normal">
                                                    {e.onlineMeeting && e.onlineMeeting.joinUrl.includes('teams') ? 'Teams Meeting' :
                                                        e.location.displayName.includes('zoom') ? 'Zoom Meeting' : e.location.displayName}
                                                </td>
                                                <td className="pop-value">{e.subject}</td>
                                                <td className="text-normal">
                                                    <label className="text-bold">{new Date(e.start.dateTime).toLocaleDateString('en-US', {
                                                        year: 'numeric', month: 'long', day: 'numeric'
                                                    })}</label><br></br>
                                                    <label>{new Date(e.start.dateTime).toLocaleTimeString()}</label>
                                                </td>
                                                <td className="pop-value w-30">
                                                    {!_isEmpty(e.location.address) ?
                                                        <div>
                                                            <span className="underline">{e.location.address.street}</span><br></br>
                                                            <span className="underline">
                                                                {e.location.address.city},&nbsp;
                                                                {e.location.address.state},&nbsp;
                                                                {e.location.address.countryOrRegion},&nbsp;
                                                                {e.location.address.postalCode}</span><br></br>
                                                        </div>
                                                        :
                                                        <span >
                                                            {e.onlineMeeting ?
                                                                <a href={`https://outlook.office.com/owa/`} className="pop-value underline" target="_blank">Join Invite</a>
                                                                :
                                                                e.location.displayName.includes('zoom') ?
                                                                    <a id="joinMeeting" href={`https://outlook.office.com/owa/`} className="pop-value underline" target="_blank">Join Invite</a>
                                                                    : ''
                                                            }
                                                        </span>}
                                                </td>
                                                <td className="text-normal">{e.responseStatus ? e.responseStatus[0].toUpperCase() + e.responseStatus.substring(1) : ''}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                :
                                <div className='mt-25'>
                                    <label>No Visits</label>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default VisitTab;
