import {get, post, put } from "./http.service";
import { getSearchListQuery } from "../utils/utils";

const VisitsService = {
    getVisitsData: (body) => get(`outlookEvents${getSearchListQuery(body)}`),
    createVisit: (body) => post(`createVisit`, body),
    getVisit: (body) => get(`getVisit${getSearchListQuery(body)}`),
    updateVisit: (body) => put(`updateVisit`, body),
    emailToCET: (body) => post(`emailToCET`, body),
};

export default VisitsService;