import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {
    emailToCET,
} from "../store/actions/visits.actions";

function Planner(props) {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');

    const sendEmail = () => {

        if (message === '') return;
        let data = {
            "schedulerName": props.profile.displayName,
            "scheduleDateTime": new Date(props.date).toISOString(),
            "cetEmail": 'apani@prologis.com',
            "schedulerMessage": message,
            "schedule": props.emailList
        }

        dispatch(emailToCET(data));
        setMessage('')
        document.getElementById('cetCanclePopup').click();
        props.clearSelection()
    }

    return (
        <div
            className="modal fade"
            id={props.id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className={props.className}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {props.title}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ fontSize: '10px' }}
                        ></button>
                    </div>
                    <div className="modal-body bg-fade">
                        {props.emailList.length > 0 ?
                            <form className="row g-3">
                                <div className="col-12">
                                    <label className="pop-key">Selected Visit Date<span style={{ color: 'red' }}>*</span></label>
                                    <input className="form-control" disabled value={new Date(props.date).toDateString()} />
                                </div>
                                <div className="col-12">
                                    <label className="pop-key">CET Contact</label>
                                    <input type="email" className="form-control" id="exampleFormControlInput1" disabled placeholder="name@example.com" value={window.CETEMAIL} />
                                </div>
                                <div className="col-12">
                                    <label className="pop-key">Message</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>
                            </form>
                            : ''}
                    </div>
                    <div className="modal-footer">
                        <button
                            id="cetCanclePopup"
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setMessage('')
                                props.clearSelection()
                            }}
                        >
                            {props.buttonSec}
                        </button>
                        <button type="button" className="btn btn-primary" onClick={sendEmail}>{props.buttonPrim}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Planner
