import _isEmpty from "lodash/isEmpty";
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Popover } from 'antd';

import calendarIcon from "../assets/img/calendar.svg";
import warehouseIcon from "../assets/img/warehouse.svg";
import PitchetmpIcon from "../assets/img/Pitchetmp.svg";
import ContactIcon from "../assets/img/Contact.svg";
import logoutIcon from "../assets/img/logout.svg";

import {
    logout,
} from "../store/actions/user.actions";

function Footer(props) {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.value);

    const logoutApp = () => {
        dispatch(logout());
    }

    const text = (
        <p>
            <label>{!_isEmpty(user) ? user.account.name : ''}</label>
        </p>
    );

    const content = (
        <div className="cursor" onClick={logoutApp}>
            <img src={logoutIcon} className="cursor" onClick={logoutApp} alt="logout" />
            <label className='logout ml-10' onClick={logoutApp}>Logout</label>
        </div>
    );

    return (

        <div className='bottom-container'>
            <ul className="nav">
                <li className={`nav-item ${props.page === 'building' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/buildingInfo">
                        <img src={warehouseIcon} alt="warehouseIcon" /><br></br>
                        <label className='menu-lable'>Visits Prep</label>
                    </Link>
                </li>
                <li className={`nav-item ${props.page === 'visit' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/planner&scheduler">
                        <img src={calendarIcon} alt="calendarIcon" /><br></br>
                        <label className="menu-lable">Visits</label>
                    </Link>
                </li>
                <li className={`nav-item ${props.page === 'pitch' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/pitch-template">
                        <img src={PitchetmpIcon} alt="PitchetmpIcon" /><br></br>
                        <label className="menu-lable">Pitch Temp.</label>
                    </Link>
                </li>
                <li className={`nav-item ${props.page === 'contact' ? 'menu-select' : ''}`}>
                    <Link className="nav-link active" aria-current="page" to="/contacts">
                        <img src={ContactIcon} alt="ContactIcon" />
                        <br></br>
                        <label className='menu-lable'>Contact</label>
                    </Link>
                </li>
                <li className="nav-item nav-link active">
                    {navigator.onLine ?
                        <Popover placement="topRight" title={text} content={content} trigger="click">
                            <div className="user-initials cursor">
                                <label className="cursor">{!_isEmpty(user) ? user.account.name.split(" ").map((n) => n[0]).join("") : ''}</label>
                            </div>
                        </Popover>
                        :
                        <div className="user-initials cursor">
                            <label className="cursor">{!_isEmpty(user) ? user.account.name.split(" ").map((n) => n[0]).join("") : ''}</label>
                        </div>
                    }
                </li>
            </ul>
        </div>

    );
}

export default Footer;