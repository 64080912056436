import ACTIONS from './types.actions';
import _isEmpty from 'lodash/isEmpty';
import { AuthService, signIn } from '../../services/auth.service';
import history from '../../utils/history.utils';
import { to, throwError } from '../../utils/utils';
import UserTemplateService from '../../services/user.services';
import { getBuildingData } from './buildings.actions';

export function userRequest() {
    return { type: ACTIONS.USER_REQUEST };
}

export function userSuccess(response) {
    return { type: ACTIONS.USER_SUCCESS, response };
}

export function getAllUsersSuccess(response) {
    return { type: ACTIONS.GET_ALL_USERS_SUCCESS, response };
}

export function userProfile(response) {
    return { type: ACTIONS.USER_PROFILE, response };
}

export function userError(error) {
    return { type: ACTIONS.USER_ERROR, error };
}

async function handleEmptyResponse() {
    const currentAccounts = await AuthService.getAllAccounts();
    if (!currentAccounts || currentAccounts.length < 1) {
        return signIn({ state: window.location.pathname });
    }
    return await AuthService.acquireTokenSilent({
        account: currentAccounts[0],
    });
}

export function checkAuth() {
    return async dispatch => {
        dispatch(userRequest(true));
        let response;
        try {
            if (navigator.onLine) {
                response = await AuthService.handleRedirectPromise();
                if (_isEmpty(response)) {
                    response = await handleEmptyResponse(response);
                    if (!response) throw new Error('No Session Found');
                }
                localStorage.setItem('userInfo', JSON.stringify(response));
                dispatch(userSuccess(response));
            } else {
                let input = JSON.parse(localStorage.getItem('userInfo'));
                dispatch(userSuccess(input));
            }
            dispatch(fetchMarketDetails());
            if (response.state) history.push(response.state);
        } catch (error) {
            console.log('checkAuthError', error);
            dispatch(userError(error));
        }
    };
}

export function fetchMarketDetails() {
    return async dispatch => {
        try {
            const [err, response] = await to(
                UserTemplateService.getMarketDetails()
            );
            if (err) throwError(err);
            let body = {};
            dispatch(userProfile(response.message));

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'login',
                userId: response.message.id,
                userPrincipalName: response.message.userPrincipalName,
            });

            if (!_isEmpty(response.message) &&
                !_isEmpty(response.message.marketDetails)
            ) {
                body = {
                    marketArray: response.message.marketDetails.map(i => {
                        return i;
                    }),
                };
            }

            dispatch(getBuildingData(body));
        } catch (error) {
            console.log(error);
        }
    };
}

export function logout() {
    return async dispatch => {
        dispatch(userRequest());
        dispatch(userSuccess({}));
        try {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const currentAccount = await AuthService.getAccountByHomeId(
                userInfo.account.homeAccountId
            );
            return await AuthService.logoutRedirect({
                account: currentAccount,
                postLogoutRedirectUri: `${window.BASE_PATH}/auth/callback`,
            });
        } catch (err) {
            console.log('err ', err);
        }
    };
}