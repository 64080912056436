import * as msal from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

const PATH = window.BASE_PATH;
const AZURE_CLIENT_ID = window.AZURE_CLIENT_ID;
const AZURE_URL = window.AZURE_URL;

const msalConfig = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: AZURE_URL,
    postLogoutRedirectUri: `${PATH}/auth/callback`,
    redirectUri: `${PATH}/auth/callback`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox || true
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
        }
      },
      piiLoggingEnabled: true,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

export const AuthService = new msal.PublicClientApplication(msalConfig);

export async function signIn(params = {}) {

  const loginReq = {
    scopes: ["user.read", "calendars.readwrite", "mailboxsettings.read"]
  };

  return AuthService.loginRedirect(loginReq);
}

export async function signOut(accountId) {
  const logoutRequest = {
    account: AuthService.getAccountByUsername(accountId),
    postLogoutRedirectUri: `${PATH}/auth/callback`,
  };
  return await AuthService.logoutRedirect(logoutRequest);
}
