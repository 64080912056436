import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { Avatar, Tag, Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Slider from 'react-slick'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  getBuildingRecord,
  getPartnerDetails,
  getCustomerContactsData,
  getCustomerPreviousSalesPopupData,
} from '../../store/actions/buildings.actions'

import nopath from '../../assets/img/NoPath.png'
import interaction from '../../assets/img/interaction.svg'
import copyButton from '../../assets/img/copy-button.svg'
import direction from '../../assets/img/direction.svg'
import SideMenu from '../../component/SideMenu'
import Header from '../../component/Header'
import SearchInput from '../../component/Search'
import Footer from '../../component/Footer'
import Modal from '../../component/Modal'
import Map from '../../component/Map'

const Buildings = ({ history, logout, loading }) => {
  const [active, setActive] = useState(0)
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const location = useLocation()
  const {
    buildingData,
    customerdata,
    previousSalesPopup,
    partnerData,
    nearByBuildings,
    customerContact,
  } = useSelector((state) => state.building)
  const buildingInfo = JSON.parse(localStorage.getItem('buildingInfo'))
  const bd = JSON.parse(localStorage.getItem('bd'))

  useEffect(() => {
    if (_isEmpty(bd)) {
      navigate('/')
    }

    if (!_isEmpty(bd)) {

      dispatch(getBuildingRecord({ "property_code": bd }))

    }
    if (_isEmpty(partnerData)) {
      dispatch(getPartnerDetails())
    }
  }, [])

  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1194,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const getPreviousSalesPopupData = (e) => {
    const bodydata = {
      customer_experience_interaction_id: e.customer_experience_interaction_id,
      customer_experience_interaction_date:
        e.customer_experience_interaction_date,
    }
    dispatch(getCustomerPreviousSalesPopupData(bodydata))
  }

  const copyAddress = () => {
    var copyText = document.getElementById('address-block')
    navigator.clipboard.writeText(copyText.textContent)

    message.success('Address copied.')
  }

  const nearbyBuildingRedirect = (e) => {
    dispatch(getBuildingRecord({ property_code: e.property_code }))
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  const redirectPartner = (e) => {
    if (e.Marketplace !== 'No') window.open(e.Marketplace_Url)
  }

  const getCustomerContacts = (e) => {
    dispatch(getCustomerContactsData(e))
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="sidenav">
          <SideMenu page="building" />
        </div>
        <div className="main pb-100">
          <div className="row">
            <Header title="Buildings" />
          </div>
          {!_isEmpty(buildingData) ? (
            <div style={{ height: `calc(100 % - 5px)` }}>
              <div className="row">
                <div className="container-search">
                  <SearchInput page="building" />
                </div>
              </div>
              <div className="row">
                <div className="white-box pd-35 pd-white-box">
                  <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-sm-4">
                    <img src={nopath} alt="ContactIcon" className="w-80" />
                  </div>
                  <div className="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-sm-8">
                    <div className="row">
                      <div className="col">
                        <label className="title">
                          {buildingData.buildingName}
                          {'  '}
                          <Tag color="default">
                            {buildingData.propertInfo.propertCode}
                          </Tag>
                        </label>
                      </div>
                    </div>
                    <div className="row pt-15">
                      <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                        <div className="description copy">
                          <div className="row">
                            <div className="col-8">
                              <span id="address-block">
                                {buildingData.buildingAdress.fullAdress}
                              </span>
                            </div>
                            <div className="col-2">
                              <Tooltip title="View on Map">
                                <img
                                  src={direction}
                                  className="cursor w-30 w-ip-53 float-end"
                                  alt="direction"
                                  data-bs-toggle="modal"
                                  data-bs-target="#mapView"
                                />
                              </Tooltip>
                              <Map
                                id="mapView"
                                title={buildingData.buildingName}
                                dTitle={"Nearby Buildings"}
                                data={[buildingData]}
                                nearByBuildings={nearByBuildings}
                                nearbyBuildingRedirect={nearbyBuildingRedirect}
                              />
                            </div>
                            <div className="col-2">
                              <img
                                src={copyButton}
                                alt="copyButton"
                                className="cursor"
                                onClick={copyAddress}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-25">
                        <label className="search-title mt-10">
                          Customers in this building
                        </label>
                        <br></br>
                        <div className="card-para" role="tablist">
                          {buildingData.buildingTenantContactDetails
                            ? buildingData.buildingTenantContactDetails.map(
                              (i, e) => {
                                return !_isEmpty(customerdata) &&
                                  !_isEmpty(customerdata[i.customerName]) ? (
                                  <Tag
                                    color={`${customerdata[i.customerName]['custlist']
                                      .length >= 5
                                      ? 'green'
                                      : customerdata[i.customerName][
                                        'custlist'
                                      ].length <= 3
                                        ? 'red'
                                        : 'gold'
                                      }`}
                                    key={`${e}-customer`}
                                    className="mb-2"
                                  >
                                    {
                                      i.buildingUnitCharacteristics.leaseInfo
                                        .leaseName
                                    }
                                  </Tag>
                                ) : (
                                  <Tag
                                    color={`red`}
                                    key={`${e}-customer`}
                                    className="mb-2"
                                  >
                                    {
                                      i.buildingUnitCharacteristics.leaseInfo
                                        .leaseName
                                    }
                                  </Tag>
                                )
                              },
                            )
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white">
                  <hr className="hr"></hr>
                  <div className="customer-tab">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {buildingData.buildingTenantContactDetails
                        ? buildingData.buildingTenantContactDetails.map(
                          (i, e) => {
                            return (
                              <li
                                key={`${e}-tabs`}
                                className="nav-item"
                                role="presentation"
                              >
                                {i.customerName ? (
                                  <button
                                    className={`nav-link tab-font ${active === e ? 'active' : ''
                                      }`}
                                    onClick={() =>
                                      getCustomerContacts(i.customerId)
                                    }
                                    id="home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target={`#tabs-${i.customerName
                                      .split(' ')[0]
                                      .replace(
                                        /[&\/\\#,+()$~%.'":*?<>{}]/g,
                                        '_',
                                      )}-id`}
                                    type="button"
                                    role="tab"
                                    aria-controls="home"
                                    aria-selected="true"
                                  >
                                    {buildingData.buildingTenantContactDetails
                                      .length > 3
                                      ? i.buildingUnitCharacteristics.leaseInfo.leaseName.split(
                                        ' ',
                                      )[0]
                                      : i.buildingUnitCharacteristics
                                        .leaseInfo.leaseName}
                                    &nbsp;&nbsp;
                                    {!_isEmpty(customerdata) &&
                                      !_isEmpty(
                                        customerdata[i.customerName],
                                      ) ? (
                                      <b
                                        className={`int-count ${customerdata[i.customerName][
                                          'custlist'
                                        ].length >= 5
                                          ? 'blue'
                                          : customerdata[i.customerName][
                                            'custlist'
                                          ].length <= 3
                                            ? 'red'
                                            : 'yellow'
                                          }`}
                                      >
                                        {
                                          customerdata[i.customerName][
                                            'custlist'
                                          ].length
                                        }
                                      </b>
                                    ) : (
                                      <b className={`int-count red`}>{0}</b>
                                    )}
                                  </button>
                                ) : (
                                  ''
                                )}
                              </li>
                            )
                          },
                        )
                        : ''}
                    </ul>
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  {buildingData.buildingTenantContactDetails.length ? (
                    buildingData.buildingTenantContactDetails.map((i, e) => {
                      return (
                        <div
                          key={`${e}-divs`}
                          className={`tab-pane fade show ${active === e ? 'active' : ''
                            }`}
                          id={`tabs-${i.customerName
                            ? i.customerName
                              .split(' ')[0]
                              .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_')
                            : ''
                            }-id`}
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div>
                            <div className="row mt-25">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <label className="cust-title-row">
                                  {
                                    i.buildingUnitCharacteristics.leaseInfo
                                      .leaseName
                                  }
                                  &nbsp;&nbsp;
                                </label>

                                <Tag color="cyan" className="mb-2">
                                  {i.buildingUnitCharacteristics.leaseInfo.cls}
                                </Tag>
                                <Tag color="cyan" className="mb-2">
                                  Lease End Date:{' '}
                                  <span style={{ color: '#414141' }}>
                                    {
                                      i.buildingUnitCharacteristics.leaseInfo
                                        .leaseEndDate
                                    }
                                  </span>
                                </Tag>
                              </div>
                            </div>
                            <div className="row mt-25">
                              <div className="col-lg-7 col-md-12 col-sm-12">
                                <div className="grid-col-left">
                                  <label className="grid-title">
                                    Past Interactions with{' '}
                                    {i.buildingUnitCharacteristics.leaseInfo
                                      .leaseName
                                      ? i.buildingUnitCharacteristics.leaseInfo.leaseName
                                        .split(' ', 1)
                                        .join(' ')
                                      : ' '}
                                    {'   '}
                                    {!_isEmpty(customerdata) &&
                                      !_isEmpty(customerdata[i.customerName]) ? (
                                      <b
                                        className={`int-count ${customerdata[i.customerName][
                                          'custlist'
                                        ].length >= 5
                                          ? 'blue'
                                          : customerdata[i.customerName][
                                            'custlist'
                                          ].length <= 3
                                            ? 'red'
                                            : 'yellow'
                                          }`}
                                      >
                                        {
                                          customerdata[i.customerName][
                                            'custlist'
                                          ].length
                                        }
                                      </b>
                                    ) : (
                                      <b className={`int-count red`}>{0}</b>
                                    )}
                                  </label>
                                  <a
                                    href={
                                      i.sf_lease_id
                                        ? window.SF_LEASE_URL + i.sf_lease_id
                                        : window.SALESFORCE_URL +
                                        i.customerId +
                                        '/view'
                                    }
                                    target="_blank"
                                    className="grid-title float-end"
                                  >
                                    <Avatar>+</Avatar> Add Interaction
                                  </a>
                                  <div className="overflow">
                                    {!_isEmpty(customerdata) &&
                                      customerdata[i.customerName] ? (
                                      customerdata[i.customerName][
                                        'custlist'
                                      ].map((e, j) => {
                                        return (
                                          <div
                                            key={`${j}-previous-sales`}
                                            className="div-sales cursor"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalopen"
                                            onClick={() =>
                                              getPreviousSalesPopupData(e)
                                            }
                                          >
                                            <label className="title">
                                              {e.subject} &nbsp;{' '}
                                              <img
                                                src={interaction}
                                                alt="interaction"
                                              />
                                            </label>
                                            <br></br>
                                            <p className="sub-title">
                                              {
                                                e.customer_experience_interaction_comments_text
                                              }
                                            </p>
                                            <table
                                              style={{ marginTop: '10px' }}
                                            >
                                              <thead>
                                                <tr className="table-row-head">
                                                  <td style={{ width: '42%' }}>
                                                    Prologis Representative
                                                  </td>
                                                  <td style={{ width: '22%' }}>
                                                    Pitch Status
                                                  </td>
                                                  <td style={{ width: '20%' }}>
                                                    Sentiment
                                                  </td>
                                                  <td>Date</td>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr className="table-row-data">
                                                  <td>
                                                    {
                                                      e.customer_experience_interaction_created_by_name
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      e.customer_experience_interaction_status
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      e.customer_experience_interaction_overall_sentiment_category
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      e.customer_experience_interaction_date
                                                    }
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        )
                                      })
                                    ) : (
                                      <label className="text-normal">
                                        No Interactions Available
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-sm-12 mt-sm-30">
                                <div className="grid-col-right">
                                  <label className="grid-title">
                                    Unit{' '}
                                    {i.buildingUnitCharacteristics.unitCode}
                                    {' Specifications'}
                                  </label>
                                  <div className="overflow">
                                    <table className="table">
                                      <tbody className="font-13">
                                        <tr>
                                          <td>Total space </td>
                                          <td>
                                            {
                                              i.buildingMarketHierarchy
                                                .statisticalMetricsNetRentableArea
                                            }
                                            {' SF'}
                                          </td>
                                        </tr>
                                        {i.buildingUnitCharacteristics
                                          .unitFloorName ? (
                                          <tr>
                                            <td>Unit Floor No.</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .unitFloorName
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                        <tr>
                                          <td>Lease Type </td>
                                          <td>
                                            {
                                              i.buildingUnitCharacteristics
                                                .leaseInfo.leaseTypeName
                                            }
                                          </td>
                                        </tr>
                                        {i.buildingUnitCharacteristics
                                          .fireSuppressionSystem ? (
                                          <tr>
                                            <td>Fire Suppression System</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .fireSuppressionSystem
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                        {i.buildingUnitCharacteristics
                                          .unitHvacWarehouseType ? (
                                          <tr>
                                            <td>Warehouse Type</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .unitHvacWarehouseType
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                        {i.buildingUnitCharacteristics
                                          .unitKVAOwnerName ? (
                                          <tr>
                                            <td>KVA Owner Name</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .unitKVAOwnerName
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                        {i.buildingUnitCharacteristics
                                          .warehouseLightingType ? (
                                          <tr>
                                            <td>Warehouse Lighting</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .warehouseLightingType
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                        {i.buildingUnitCharacteristics
                                          .unitMezzaineLightingType ? (
                                          <tr>
                                            <td>Mezzaine Lighting Type</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .unitMezzaineLightingType
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                        {i.buildingUnitCharacteristics
                                          .unitOfficeLightingType ? (
                                          <tr>
                                            <td>Office Lighting Type</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .unitOfficeLightingType
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                        {i.buildingUnitCharacteristics
                                          .dockHighDoors ? (
                                          <tr>
                                            <td>Dock Doors</td>
                                            <td>
                                              {
                                                i.buildingUnitCharacteristics
                                                  .dockHighDoors
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ''
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-25">
                              <div className="col-lg-7 col-md-12 col-sm-12">
                                <div className="grid-col-left">
                                  <label className="grid-title">
                                    {i.buildingUnitCharacteristics.leaseInfo
                                      .leaseName
                                      ? i.buildingUnitCharacteristics.leaseInfo.leaseName.split(
                                        ',',
                                      )[0]
                                      : ' '}
                                    {'   Contacts'}
                                  </label>
                                  <br></br>
                                  <div className="overflow">
                                    {!_isEmpty(customerContact) ? (
                                      customerContact.map((e, j) => {
                                        return (
                                          <div
                                            key={`representatives-${j}`}
                                            className="rep-grid-box"
                                          >
                                            <div className="initials">
                                              <span className="text">
                                                {e.customer_contact_first_name
                                                  ? e
                                                    .customer_contact_first_name[0]
                                                  : ''}
                                                {e.customer_contact_last_name
                                                  ? e
                                                    .customer_contact_last_name[0]
                                                  : ''}
                                              </span>
                                            </div>
                                            <div className="div-content width-20 w-20">
                                              <label className="title">
                                                {e.customer_contact_first_name +
                                                  ' ' +
                                                  e.customer_contact_last_name}
                                              </label>
                                              <br></br>
                                              <span className="sub-title">
                                                {e.customer_contact_title_name}
                                              </span>
                                            </div>
                                            <div className="div-content width-33 w-45">
                                              <label className="text-bold">
                                                Mail:&nbsp;{' '}
                                              </label>
                                              <a
                                                href={`mailto:${e.customer_contact_email_text}`}
                                              >
                                                <span className="text-normal color-blue bold underline">
                                                  {
                                                    e.customer_contact_email_text
                                                  }
                                                </span>
                                              </a>
                                              <br></br>
                                              <label className="text-bold">
                                                Tel:&nbsp;{' '}
                                              </label>
                                              <a
                                                href={`tel:${e.customer_contact_mobile_phone_text}`}
                                              >
                                                <span className="text-normal color-blue bold underline">
                                                  {
                                                    e.customer_contact_mobile_phone_text
                                                  }
                                                </span>
                                              </a>
                                            </div>
                                            <div className="div-content width-25">
                                              <label className="text-bold">
                                                Decision Maker: &nbsp;{' '}
                                              </label>
                                              <span className="text-normal"></span>
                                              <br></br>
                                            </div>
                                          </div>
                                        )
                                      })
                                    ) : (
                                      <label className="text-normal">
                                        No Representatives Available
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-sm-12 mt-sm-30">
                                <div className="grid-col-right">
                                  <label className="grid-title">
                                    Prologis Partners
                                  </label>
                                  <br></br>
                                  <div className="overflow">
                                    {partnerData.length ? (
                                      partnerData.map((i, e) => {
                                        return (
                                          <div
                                            key={`${e}-partner-data`}
                                            className="partner-grid-box cursor"
                                            onClick={() => redirectPartner(i)}
                                          >
                                            <label className="text-bold">
                                              {i['Category 1']} -{' '}
                                              {i['Category 2']}
                                            </label>
                                            <br></br>
                                            <span className="text-normal">
                                              {i.Vendor}
                                            </span>
                                          </div>
                                        )
                                      })
                                    ) : (
                                      <label className="text-normal">
                                        No Data Available
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-25">
                              <div className="col-lg-7 col-md-12 col-sm-12">
                                <div className="grid-col-left">
                                  <label className="grid-title">
                                    Equipment Installed
                                  </label>
                                  <div className="overflow">
                                    {i.buildingUnitCharacteristics
                                      .warehouseLightingType &&
                                      i.buildingUnitCharacteristics.warehouseLightingType.toLowerCase() ===
                                      'led' ? (
                                      <div>
                                        <label className="text-bold">
                                          Warehouse Lighting : &nbsp;&nbsp;{' '}
                                        </label>
                                        <span className="text-normal">
                                          {
                                            i.buildingUnitCharacteristics
                                              .warehouseLightingType
                                          }
                                        </span>
                                        <br></br>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {i.buildingUnitCharacteristics
                                      .unitOfficeLightingType &&
                                      i.buildingUnitCharacteristics.unitOfficeLightingType.toLowerCase() ===
                                      'led' ? (
                                      <div>
                                        <label className="text-bold">
                                          Office Lighting Type : &nbsp;&nbsp;
                                        </label>
                                        <span className="text-normal">
                                          {
                                            i.buildingUnitCharacteristics
                                              .unitOfficeLightingType
                                          }
                                        </span>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {i.buildingUnitCharacteristics
                                      .warehouseLightingType &&
                                      i.buildingUnitCharacteristics.warehouseLightingType.toLowerCase() !==
                                      'led' &&
                                      i.buildingUnitCharacteristics
                                        .unitOfficeLightingType &&
                                      i.buildingUnitCharacteristics.unitOfficeLightingType.toLowerCase() !==
                                      'led' ? (
                                      <div>
                                        <label className="text-normal">
                                          No Data Available
                                        </label>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {i.buildingUnitCharacteristics
                                      .warehouseLightingType == null &&
                                      i.buildingUnitCharacteristics
                                        .unitOfficeLightingType == null ? (
                                      <div>
                                        <label className="text-normal">
                                          No Data Available
                                        </label>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5 col-md-12 col-sm-12 mt-sm-30">
                                <div className="grid-col-right">
                                  <label className="grid-title">
                                    Recomended Products
                                  </label>
                                  <div className="overflow">
                                    <Tag color="#2C728A">Lighting</Tag>
                                    <Tag color="#2C728A">LightSmart</Tag>
                                    <Tag color="#2C728A">Racking</Tag>
                                    <Tag color="#2C728A">Forklift</Tag>
                                    <Tag color="#2C728A">HVAC Equipment</Tag>
                                    <Tag color="#2C728A">Smart Building</Tag>
                                    <Tag color="#2C728A">Finishes</Tag>
                                    <Tag color="#2C728A">Fans</Tag>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <label className="text-normal">No Data Available</label>
                  )}
                </div>
                <div className="container">
                  <div className="row mt-25">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="grid-col-left pb-30">
                        <div className="row">
                          <div className="col-6">
                            <label className="grid-title">
                              Nearby Buildings
                            </label>
                          </div>
                        </div>
                        <div className="row mt-10 mr-30 ml-30">
                          {!_isEmpty(nearByBuildings) ? (
                            <Slider {...settings}>
                              {nearByBuildings.map((i, e) => {
                                return (
                                  <div
                                    className="col-sm-4 cursor"
                                    key={`carousel-card${e}`}
                                    onClick={() => nearbyBuildingRedirect(i)}
                                  >
                                    <div className="card mr-15">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col-lg-3 col-md-3 col-sm-12 ">
                                            <img
                                              src={nopath}
                                              className="w-99 bblr"
                                              alt="ContactIcon"
                                            />
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-12 ">
                                            <label className="nb-t mt-10">
                                              {i.building_name}
                                            </label>
                                            <p className="nb-des">
                                              {i.building_full_address_text}
                                            </p>
                                          </div>
                                          <div className="col-lg-3 col-md-3 col-sm-12 ">
                                            <label className="nb-t mt-10"></label>
                                            <p className="grid-title">
                                              {i.distance === 0
                                                ? '0 Mi'
                                                : i.distance
                                                  ? i.distance.toFixed(2) + ' Mi'
                                                  : ''}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                              {nearByBuildings.length >= 10 ? (
                                <div className="col-sm-4 cursor">
                                  <div className="card mr-15">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 ">
                                          <img
                                            src={nopath}
                                            className="w-99 bblr visibility"
                                            alt="ContactIcon"
                                          />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                                          <label className="nb-t mt-10"></label>
                                          <p className="grid-title text-center">
                                            <Link
                                              className="nb-link"
                                              to="/"
                                              state={{
                                                coords: !_isEmpty(buildingData)
                                                  ? buildingData.buildingLocation
                                                  : '',
                                              }}
                                            >
                                              Load More Results
                                            </Link>
                                          </p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 "></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </Slider>
                          ) : (
                            <label className="text-normal text-center">
                              No Nearby Buildings Available
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : bd ? (
            <label className="text-normal">No Data Available</label>
          ) : (
            navigate('/')
          )}
        </div>
        {previousSalesPopup ? (
          <Modal
            id="modalopen"
            className="modal-dialog modal-xl"
            buttonSec="Dismiss"
            buttonPrim="Print"
            data={previousSalesPopup}
          />
        ) : (
          ''
        )}
        <Footer page="building" />
      </div>
    </React.Fragment>
  )
}

Buildings.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  logout: PropTypes.func,
}

export default Buildings
