import _isEmpty from 'lodash/isEmpty';
import ACTIONS from './types.actions';
import buildingService from '../../services/buildings.services';
import { to, throwError } from '../../utils/utils';

export function buildingData(data) {
    return { type: ACTIONS.BUILDING_DATA, data };
}

export function nearbyBuildings(data) {
    return { type: ACTIONS.NEAR_BY_BUILDING_DATA, data };
}

export function searchBuildingData(data) {
    return { type: ACTIONS.SEARCH_BUILDING_DATA, data };
}

export function partnerData(data) {
    return { type: ACTIONS.PARTNER_DATA, data };
}

export function customerPreviousSalesData(data) {
    return { type: ACTIONS.PREVIOUS_SALES_DATA, data };
}

export function customerPreviousSalesPopupData(data) {
    return { type: ACTIONS.PREVIOUS_SALES_POPUP_DATA, data };
}

export function customerContacts(data) {
    return { type: ACTIONS.GET_CUSTOMER_CONTACTS, data };
}

export function marketDetails(response) {
    return { type: ACTIONS.GET_MARKET_DETAILS, response };
}

export function subMarketDetails(data) {
    return { type: ACTIONS.GET_SUB_MARKET_DETAILS, data };
}

export function setDistance(data) {
    return { type: ACTIONS.GET_BUILDING_DISTANCE, data };
}

export function setRetainedValue(data) {
    return { type: ACTIONS.GET_RETAINED_VALUE, data };
}

export function setAutoFill(data) {
    return { type: ACTIONS.GET_AUTO_FILL, data };
}

export function getBuildingData(body) {
    return async dispatch => {
        dispatch(searchBuildingData([]));
        try {
            const [err, response] = await to(buildingService.getBuildingData(body));
            if (err) throwError(err);
            dispatch(searchBuildingData(response.data.filter(i => i.buildingLocation.lat !== null && i.buildingLocation.log !== null)));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getBuildingRecord(body) {
    return async dispatch => {
        try {
            dispatch(customerPreviousSalesData([]));
            const [err, response] = await to(buildingService.getBuildingData(body));
            if (err) throwError(err);
            dispatch(buildingData(response.data[0]));
            localStorage.setItem('buildingInfo', JSON.stringify(response.data[0]));
            dispatch(
                getCustomerPreviousSalesData(response.data[0].propertInfo.propertCode)
            );
            dispatch(
                getNearbyBuildingData(
                    response.data[0].buildingLocation,
                    response.data[0].propertInfo.propertCode
                )
            );
            dispatch(
                getCustomerContactsData(
                    response.data[0].buildingTenantContactDetails[0].customerId
                )
            );
        } catch (error) {
            throwError(error);
        }
    };
}

export function autoComplete(body) {
    return async dispatch => {
        try {
            const [err, response] = await to(buildingService.autocomplete(body));
            if (err) throwError(err);
            dispatch(setAutoFill(response.data))
        } catch (error) {
            throwError(error);
        }
    };
}

export function getPartnerDetails() {
    return async dispatch => {
        try {
            const [err, response] = await to(buildingService.getPartnerDetails());
            if (err) throwError(err);
            dispatch(partnerData(response));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getCustomerPreviousSalesData(data) {
    return async dispatch => {
        try {
            let body = {
                property_code: data,
            };
            const [err, response] = await to(
                buildingService.getCustomerPreviousSalesData(body)
            );
            if (err) throwError(err);
            dispatch(customerPreviousSalesData(response));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getCustomerContactsData(data) {
    return async dispatch => {
        try {
            dispatch(customerContacts([]));
            let body = {
                customer_id: data,
            };
            const [err, response] = await to(
                buildingService.getCustomerPreviousSalesData(body)
            );
            if (err) throwError(err);
            dispatch(customerContacts(response));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getCustomerPreviousSalesPopupData(data) {
    return async dispatch => {
        try {
            const [err, response] = await to(
                buildingService.getCustomerPreviousSalesPopupData(data)
            );
            if (err) throwError(err);
            dispatch(customerPreviousSalesPopupData(response));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getNearbyBuildingData(data, id) {
    return async dispatch => {
        try {
            dispatch(searchBuildingData([]));
            let body = {
                latitude: data.lat,
                longitude: data.log,
            };
            const [err, response] = await to(
                buildingService.getNearbyBuildingData(body)
            );
            if (err) throwError(err);

            if (id)
                dispatch(
                    nearbyBuildings(!_isEmpty(response) ?
                        response.nearestBuildings.filter((i, e) => {
                            return i.property_code !== id && e <= 10;
                        }) : []
                    )
                );
            else
                dispatch(
                    searchBuildingData(!_isEmpty(response) ? response.nearestBuildings : [])
                );
            dispatch(setDistance(response.radius));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getMarketData(body) {
    return async dispatch => {
        try {
            const [err, response] = await to(buildingService.getMarketData(body));
            if (err) throwError(err);
            dispatch(
                marketDetails(!_isEmpty(response) ? [
                    ...new Set(
                        response.data.map(i => {
                            return i.market_name;
                        })
                    ),
                ] : [])
            );
        } catch (error) {
            throwError(error);
        }
    };
}

export function getSubMarketData(body) {
    return async dispatch => {
        try {
            const [err, response] = await to(buildingService.getMarketData(body));
            if (err) throwError(err);
            dispatch(
                subMarketDetails(!_isEmpty(response) ? [
                    ...new Set(
                        response.data.map(i => {
                            return i.submarket_name;
                        })
                    ),
                ] : [])
            );
        } catch (error) {
            throwError(error);
        }
    };
}