import React from 'react';
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import SideMenu from '../../component/SideMenu';
import Header from '../../component/Header';
import Footer from '../../component/Footer';

import teams from "../../assets/img/teams.png";
import outlook from "../../assets/img/outlook.png";
import zoom from "../../assets/img/zoom.png";

const Contact = () => {

    const user = useSelector(state => state.user.value);

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className="sidenav">
                    <SideMenu
                        page="contact"
                    />
                </div>
                <div className="main">
                    <div className="row">
                        <Header
                            title='Contacts'
                        />
                    </div>
                    <div className='border-top' style={{ height: `calc(100% - 5px)` }}>

                        <label className='catalogue-title lable-margin-top'>Choose that contact medium</label>
                        <div className="row container-margin-top">
                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 mt-sm-30">
                                <div className='contact-card text-center pd-25'>
                                    <a href="https://prologis.zoom.us" target="_blank">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <img src={zoom} alt="outlook" />
                                            </div>
                                            <div className='col-12'>
                                                <label className='contact-text'>Create Zoom Meeting</label>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 mt-sm-30">
                                <div className='contact-card text-center pd-25'>
                                    <a href={`https://teams.microsoft.com/_#/?lm=deeplink&lmsrc=officeWaffle&login_hint_safe=${!_isEmpty(user) ? user.account.username : ''}`} target="_blank">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <img src={teams} alt="zoom" />
                                            </div>
                                            <div className='col-12'>
                                                <label className='contact-text'>Create Teams Meeting</label>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 mt-sm-30">
                                <div className='contact-card text-center pd-25'>
                                    <a href="mailto:contact@prologis.com">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <img src={outlook} alt="zoom" />
                                            </div>
                                            <div className='col-12'>
                                                <label className='contact-text'>Create New Email</label>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer page="contact" />
            </div>
        </React.Fragment>
    );
}

export default Contact;
