
import Buildings from '../containers/Buildings/Buildings';
import Pitch from '../containers/PitchTemplate/Pitch';
import PitchViwer from '../containers/PitchViewer/PitchViewer';
import Search from '../containers/Search/Search';
import Contact from '../containers/Contact/Contact';
import Visits from '../containers/Visits/Visits';
import NoMatch from '../containers/NoMatch/NoMatch';

import { ROUTES } from "../utils/constants";

const routes = [
  {
    path: ROUTES.HOME.path,
    exact: true,
    name: ROUTES.HOME.name,
    displayName: ROUTES.HOME.title,
    component: <Buildings />,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
  },
  {
    path: ROUTES.PITCHTEMPLATE.path,
    exact: true,
    name: ROUTES.PITCHTEMPLATE.name,
    displayName: ROUTES.PITCHTEMPLATE.title,
    component: <Pitch />,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
  },
  {
    path: ROUTES.PITCHVIEWER.path,
    exact: true,
    name: ROUTES.PITCHVIEWER.name,
    displayName: ROUTES.PITCHVIEWER.title,
    component: <PitchViwer />,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
  },
  {
    path: ROUTES.SEARCH.path,
    exact: true,
    name: ROUTES.SEARCH.name,
    displayName: ROUTES.SEARCH.title,
    component: <Search />,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
  },
  {
    path: ROUTES.VISITS.path,
    exact: true,
    name: ROUTES.VISITS.name,
    displayName: ROUTES.VISITS.title,
    component: <Visits />,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
  },
  {
    path: ROUTES.CONTACT.path,
    exact: true,
    name: ROUTES.CONTACT.name,
    displayName: ROUTES.CONTACT.title,
    component: <Contact />,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
  },
  {
    path: '',
    exact: true,
    name: '404 Not Found',
    displayName: '404 Not Found',
    component: <NoMatch />,
    isPrivate: true,
    icon: "HomeOutlined",
    isDisabled: false,
    module: 0,
    category: "user",
    action: "view",
  },
];

export default routes;
