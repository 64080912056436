import _isEmpty from "lodash/isEmpty";
import { Divider, List, Popover } from 'antd';
import { MoreOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';

import {
    getVisitsDetials,
    updateVisit,
} from "../../store/actions/visits.actions";

import Planner from "../../component/Planner";
import CET from "../../component/CETEmail";
import SchedulerNotes from "../../component/SchedulerNotes";

import trash_icon from '../../assets/img/trash_icon.svg';
import notes_add from '../../assets/img/notes_add.svg';
function VisitTab(props) {

    const dispatch = useDispatch();
    let navigate = useNavigate()
    let { state } = useLocation()

    const [customerList, setCustomerList] = useState([])
    const [addCustomer, setAddCustomer] = useState([])
    const [emailList, setEmailList] = useState([])
    const [butDisable, setButDisable] = useState(true)
    const [emailBtn, setEmailBtn] = useState(true)
    const { profile } = useSelector((state) => state.user)
    const { scheduledData, updatedCall } = useSelector(state => state.visits);
    const [noteDate, setNoteDate] = useState({});
    const [date, setDate] = useState('');
    const [coName, setCoName] = useState('');


    useEffect(() => {
        if (!_isEmpty(profile)) {
            setDate(new Date().toDateString())
            dispatch(getVisitsDetials({
                schedulerName: profile.displayName,
                startDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                endDate: new Date(new Date().setHours(23, 59, 59, 999)).toISOString()
            }))
        }
    }, [profile, dispatch]);

    useEffect(() => {
        if(updatedCall && date) {
            dispatch(getVisitsDetials({
                schedulerName: profile.displayName,
                startDate: new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString(),
                endDate: new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString()
            }))
        }
    }, [updatedCall])

    useEffect(() => {
        if (!_isEmpty(state)) {
            setCustomerList(state.selectCustomer)
        }
    }, [state]);

    useEffect(() => {
        if (!addCustomer.length)
            setButDisable(true)
        else
            setButDisable(false)
    }, [addCustomer]);

    useEffect(() => {
        if (!emailList.length)
            setEmailBtn(true)
        else
            setEmailBtn(false)
    }, [emailList]);

    const selectCustomerForVisit = (item) => {
        if (addCustomer.some(i => i.customerId === item.customerId)) {
            setAddCustomer(addCustomer.filter(i => i.customerId !== item.customerId));
        } else {
            setAddCustomer(addCustomer => [...addCustomer, item]);
        }
    }

    const selectCustomerForEmail = (item) => {
        let list = {
            "meetingStartTime": new Date(item.meetingStartTime).toISOString(),
            "meetingEndTime": new Date(item.meetingEndTime).toISOString(),
            "schedulerCoordinater": item.schedulerCoordinater,
            "tag": item.tag,
            "buildingId": item.buildingId,
            "customerId": item.customerId,
            "leaseId": item.leaseId,
            "customerName": item.customerName,
            "customerContact": item.customerContact,
            "unitCode": item.unitCode,
        }

        if (emailList.some(i => i.customerId === list.customerId)) {
            setEmailList(emailList.filter(i => i.customerId !== list.customerId));
        } else {
            setEmailList(emailList => [...emailList, list]);
        }
    }

    const changeDate = (e) => {
        let _d = new Date(date)
        _d.setDate(_d.getDate() + e)
        setDate(_d.toDateString())

        dispatch(getVisitsDetials({
            schedulerName: profile.displayName,
            startDate: new Date(_d.setHours(0, 0, 0, 0)).toISOString(),
            endDate: new Date(_d.setHours(23, 59, 59, 999)).toISOString()
        }))
    }

    const updateVisits = (item) => {
        let data = {
            "scheduleDateTime": new Date(item.scheduleDateTime).toISOString(),
            "meetingStartTime": new Date(item.meetingStartTime).toISOString(),
            "meetingEndTime": new Date(item.meetingEndTime).toISOString(),
            "customerName": item.customerName,
            "status": item.status,
            "customerContact": item.customerContact,
            "isSalesForceDone": item.isSalesForceDone,
            "isCancel": item.isCancel,
            "isMeetingComplete": item.isMeetingComplete,
            "buildingId": item.buildingId,
            "schedulerCoordinater": coName,
            "tag": item.tag,
            "unitCode": item.unitCode,
            "isFollowUpDone": item.isFollowUpDone,
            "leaseId": item.leaseId,
            "schedulerName": item.schedulerName,
            "isMeetingConfirmed": item.isMeetingConfirmed,
            "customerId": item.customerId
        }

        dispatch(updateVisit(data));
        setCoName('')
    }

    const clearSelection = () => {
        const nodelist = document.querySelectorAll("input");
        for (let i = 0; i < nodelist.length; i++) {
            nodelist[i].checked = false;
        }
        setAddCustomer([]);
        setEmailList([]);
    };

    const remove = (item) => {
        if (customerList.some(i => i.customerId === item.customerId)) {
            setCustomerList(customerList.filter(i => i.customerId !== item.customerId));
            navigate('/planner&scheduler', { state: { selectCustomer: state.selectCustomer.filter(i => i.customerId !== item.customerId) } })
        }
    }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link inner-tab-font active" id="addedVisits-tab" data-bs-toggle="tab" data-bs-target="#addedVisits"
                            type="button" role="tab" aria-controls="addedVisits" aria-selected="true">
                            My Planner</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link inner-tab-font" id="mySchedule-tab" data-bs-toggle="tab" data-bs-target="#mySchedule"
                            type="button" role="tab" aria-controls="mySchedule" aria-selected="false">
                            My Schedule</button>
                    </li>
                </ul>
                <div className="tab-content bg-white pd-20" id="myTabContent">
                    <div className="tab-pane fade show active" id="addedVisits" role="tabpanel" aria-labelledby="addedVisits-tab">
                        {!_isEmpty(customerList) ?
                            <div className="table-responsive">
                                <Divider orientation="left">{"Customers"}</Divider>
                                <List
                                    footer={
                                        <div className='button-container text-right'>
                                            <button type="button"
                                                className="btn btn-primary float-right" disabled={butDisable}
                                                data-bs-toggle="modal" data-bs-target="#planerPopup">
                                                Plan Visit
                                            </button>
                                        </div>
                                    }
                                    bordered
                                    dataSource={customerList}
                                    itemLayout="horizontal"
                                    renderItem={item => (
                                        <List.Item>
                                            <div className="form-check form-check-inline w-30">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue={item.customerName} onChange={() => selectCustomerForVisit(item)} />
                                                <label className="form-check-label title">{item.customerName}</label>
                                                <p className="form-check-label pop-key">{item.buildingName}</p>
                                            </div>
                                            <div className="form-check form-check-inline w-40">
                                                <label className="form-check-label pop-value underline">{item.buildingAddress}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <p className="form-check-label pop-key">{"Unit: "}{item.unitCode}</p>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <img src={trash_icon} onClick={() => remove(item)} alt="delete" className="w-80" />
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                            : ''}
                    </div>
                    <div className="tab-pane fade" id="mySchedule" role="tabpanel" aria-labelledby="mySchedule-tab">
                        <div className="table-responsive mb-10">
                            <List
                                header={
                                    <div className='button-container text-center'>
                                        <ArrowLeftOutlined className="float-start" onClick={() => changeDate(-1)} />
                                        <label className="tab-font">{date}</label>
                                        <ArrowRightOutlined className="float-end" onClick={() => changeDate(+1)} />
                                    </div>
                                }
                                bordered
                                dataSource={scheduledData}
                                itemLayout="horizontal"
                            />
                        </div>
                        <div className="table-responsive">
                            {!_isEmpty(scheduledData) ?
                                <List
                                    footer={
                                        <div className='button-container text-right'>
                                            <button type="button"
                                                className="btn btn-primary float-right" disabled={emailBtn}
                                                data-bs-toggle="modal" data-bs-target="#cetPopup">
                                                Send To CET
                                            </button>
                                        </div>
                                    }
                                    bordered
                                    dataSource={scheduledData}
                                    itemLayout="horizontal"
                                    renderItem={item => (
                                        <List.Item>
                                            <div className="form-check form-check-inline w-25">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue={item.customerName} onChange={() => selectCustomerForEmail(item)} />
                                                <label className="form-check-label title">{item.customerName}</label>
                                                <p className="form-check-label pop-key" style={{ 'margin-bottom': 0 }}>{item.buildingName}</p>
                                                <p className="form-check-label pop-key">{"Unit: "}{item.unitCode}</p>
                                            </div>
                                            <div className="form-check form-check-inline w-15">
                                                <label className="form-check-label pop-value underline">{item.buildingAddress}</label>
                                            </div>
                                            <div className="form-check form-check-inline w-15">
                                                <label className="form-check-label pop-key">{new Date(item.meetingStartTime).toLocaleTimeString()}</label>
                                                <p className="form-check-label pop-value">{item.tag}</p>
                                            </div>
                                            <div className="form-check form-check-inline w-15">
                                                <label className="form-check-label pop-key">
                                                    <Popover placement="topRight" title={'Contact'}
                                                        content={
                                                            <div>
                                                                <input type="text" className="form-control" id="contact" value={coName ? coName : item.schedulerCoordinater} onChange={(e) => setCoName(e.target.value)} autoComplete="off" />
                                                                <a className="" onClick={() => updateVisits(item)}>Save</a>
                                                            </div>
                                                        }
                                                        trigger="hover">
                                                        {"Contact Person"}
                                                    </Popover>
                                                </label>
                                                <p className="form-check-label pop-value">{item.schedulerCoordinater}</p>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <img src={notes_add} onClick={() => setNoteDate(item)} alt="delete" className="w-80 cursor" data-bs-toggle="modal" data-bs-target="#notesPopup" />
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <p className="form-check-label pop-key">
                                                    <button type="button" className="btn btn-secondary">
                                                        <a
                                                            href={window.SALESFORCE_URL +
                                                                item.customerId +
                                                                '/view'
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="grid-title float-end"
                                                        >
                                                            Log Interaction
                                                        </a>
                                                    </button></p>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                                : ''}

                        </div>
                    </div>
                </div>
            </div>
            <Planner
                title="Plan Visits"
                id="planerPopup"
                className="modal-dialog modal-lg"
                buttonSec="Dismiss"
                buttonPrim="Schedule"
                customerList={addCustomer}
                clearSelection={clearSelection}
                remove={remove}
            />
            {emailList.length > 0 && !_isEmpty(profile) ?
                <CET
                    title="Plan Visits"
                    id="cetPopup"
                    className="modal-dialog modal-lg"
                    buttonSec="Dismiss"
                    buttonPrim="Send Email"
                    emailList={emailList}
                    profile={profile}
                    date={scheduledData[0].scheduleDateTime}
                    clearSelection={clearSelection}
                />
                : ''}

            <SchedulerNotes
                title="Quick notes"
                id="notesPopup"
                className="modal-dialog modal-lg"
                buttonSec="Cancel"
                buttonPrim="Save"
                data={noteDate}
                clearSelection={clearSelection}
            />
        </React.Fragment>
    );
}

export default VisitTab;
