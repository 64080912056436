import React from 'react';
import { Link } from 'react-router-dom';
import globeIcon from "../assets/img/Layer 2.svg";
import PWAOfflineStatus from "./PWAOffline";

function Header(props) {

    return (

        <header className="header">
            {props.bookmark ?
                <Link to={props.boomarkLink} className="nav-link float-start pa pl-0">
                    <label style={{ fontSize: '12px' }} className="title-left cursor">{props.bookmarkTitle}</label>
                </Link>

                :

                <Link className="nav-link float-start pa pl-0" aria-current="page" to="/">
                    <img src={globeIcon} className="img-globe-p cursor" alt="Home" />
                    <label className="title-left cursor">ESM Tools</label>
                </Link>
            }
            <label className='nav-link title-center bold m-hide'>{props.title}</label>
            <PWAOfflineStatus />
        </header>


    );
}

export default Header;
