import React from 'react';

import SideMenu from '../../component/SideMenu';
import Header from '../../component/Header';
import Footer from '../../component/Footer';

import VisitTab from "./VisitTab";
import SchedulerTab from "./SchedulerTab"

function Visits() {

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className="sidenav">
                    <SideMenu
                        page="visit"
                    />
                </div>
                <div className="main">
                    <div className="row">
                        <Header
                            title='Scheduler'
                        />
                    </div>
                    <div className='border-top' style={{ height: `calc(100% - 5px)` }}>
                        <div className='mt-25'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link tab-font active"
                                        id="scheduler-tab" data-bs-toggle="tab" data-bs-target="#scheduler"
                                        type="button" role="tab" aria-controls="scheduler"
                                        aria-selected="true">
                                        SCHEDULER</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link tab-font"
                                        id="profile-tab" data-bs-toggle="tab" data-bs-target="#visits"
                                        type="button" role="tab" aria-controls="visits"
                                        aria-selected="false">
                                        VISITS</button>
                                </li>
                            </ul>
                            <div className="tab-content bg-white" id="myTabContent">
                                <div className="tab-pane fade show active" id="scheduler" role="tabpanel" aria-labelledby="scheduler-tab">
                                    <SchedulerTab />
                                </div>
                                <div className="tab-pane fade show" id="visits" role="tabpanel" aria-labelledby="visits-tab">
                                    <VisitTab />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer page="visit" />
            </div>
        </React.Fragment>

    );
}

export default Visits;
