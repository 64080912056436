import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { DatePicker, TimePicker } from 'antd';
import { useDispatch } from "react-redux";
import {
    createSchedule,
} from "../store/actions/visits.actions";

function Planner(props) {
    const dispatch = useDispatch();
    const [customers, setCustomers] = useState([])
    const [datePicker, setDatePicker] = useState('')

    useEffect(() => {
        setCustomers(props.customerList)
    }, [props.customerList])

    const disabledDate = current => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    const setDate = (date) => {
        setDatePicker(date)
        if (date) {
            customers.map((i) => {
                i.scheduleDateTime = date._d.toISOString()
            })
        } else {
            customers.map((i) => {
                i.scheduleDateTime = ''
            })
        }
    };

    const setTime = (e, event) => {
        if (customers.some(i => i.customerId === e.customerId)) {
            customers.map((i) => {
                if (i.customerId === e.customerId) {
                    i.meetingStartTime = event._d.toISOString()
                    i.meetingEndTime = event._d.toISOString()
                }
            })
        }
    };

    const setContact = (e, event) => {
        if (customers.some(i => i.customerId === e.customerId)) {
            customers.map((i) => {
                if (i.customerId === e.customerId) {
                    i.schedulerCoordinater = event.target.value
                }
            })
        }
    }

    const setTags = (e, event) => {
        if (customers.some(i => i.customerId === e.customerId)) {
            customers.map((i) => {
                if (i.customerId === e.customerId) {
                    i.tag = event.target.value
                }
            })
        }
    }

    const sendScheduler = () => {
        if (customers.some(i => i.scheduleDateTime === null)) return;
        if (customers.some(i => i.meetingStartTime === null)) return;
        if (customers.some(i => i.schedulerCoordinater === '')) return;

        dispatch(createSchedule({ data: customers }));
        customers.map(i => props.remove(i));
        setCustomers([]);
        setDatePicker('');
        props.clearSelection();
    }

    return (
        <div
            className="modal fade"
            id={props.id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className={props.className}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {props.title}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ fontSize: '10px' }}
                        ></button>
                    </div>
                    <div className="modal-body bg-fade">
                        <form className="row g-3">
                            <div className="col-12">
                                <label className="pop-key">Select Visit Date<span style={{ color: 'red' }}>*</span></label>
                                <DatePicker className="form-control custme-form-control"
                                    format="MMM, DD YYYY" onChange={setDate} disabledDate={disabledDate} value={datePicker} />
                            </div>

                            {customers ?
                                customers.map((e, i) => {
                                    return (
                                        <div className='row mt-10' key={e.customerName}>
                                            <div className="col-12">
                                                <label className="pop-value">{e.customerName}</label>
                                            </div>
                                            <div className="col-md-2">
                                                <label className="pop-key">Select Time<span style={{ color: 'red' }}>*</span></label>
                                                <TimePicker className="form-control custme-form-control"
                                                    use12Hours format="h:mm a" onChange={(event) => setTime(e, event)} />
                                            </div>
                                            <div className="col-md-5">
                                                <label className="pop-key">Contact<span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control" id="contact" onChange={(event) => setContact(e, event)} autoComplete="off" />
                                            </div>
                                            <div className="col-md-5">
                                                <label className="pop-key">Add Tag</label>
                                                <input type="text" className="form-control" id="tags" onChange={(event) => setTags(e, event)} autoComplete="off" />
                                            </div>
                                        </div>
                                    )
                                }) : ''}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            id="plannerPop"
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setCustomers([])
                                setDatePicker('')
                                props.clearSelection()
                            }}
                        >
                            {props.buttonSec}
                        </button>
                        <button type="button" className="btn btn-primary" onClick={sendScheduler}>{props.buttonPrim}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Planner
