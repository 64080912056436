import mapValues from 'lodash/mapValues';

const ACTIONS = {
    APP_RESET: null,

    USER_REQUEST: null,
    USER_SUCCESS: null,
    USER_ERROR: null,
    USER_PROFILE: null,
    GET_ALL_USERS_SUCCESS: null,
    GET_MARKET_DETAILS: null,
    GET_SUB_MARKET_DETAILS: null,
    GET_BUILDING_DISTANCE: null,
    GET_CUSTOMER_CONTACTS: null,
    GET_RETAINED_VALUE: null,
    GET_AUTO_FILL: null,

    BUILDING_DATA: null,
    NEAR_BY_BUILDING_DATA: null,
    SEARCH_BUILDING_DATA: null,
    PARTNER_DATA: null,
    PREVIOUS_SALES_DATA: null,
    PREVIOUS_SALES_POPUP_DATA: null,

    PITCH_TEMPLATE_DATA: null,
    EMAIL_TEMPLATE_DATA: null,
    EMAIL_TEMPLATE_HTML: null,
    EMAIL_STATUS: null,

    GET_VISITS_DATA: null,
    GET_SCHEDULED_DATA: null,
    GET_UPCOMING_DATA: null,
    GET_PAST_DATA: null,
    UPDATED_CALL: null
};

export default mapValues(ACTIONS, (v, k) => k);