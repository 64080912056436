import {get } from "./http.service";
import { getSearchListQuery } from "../utils/utils";

const BuildingService = {
    getBuildingData: (body) => get(`buildingdata${getSearchListQuery(body)}`),
    getPartnerDetails: () => get(`partnerlist`),
    getCustomerPreviousSalesData: (body) => get(`customerInteraction${getSearchListQuery(body)}`),
    getCustomerPreviousSalesPopupData: (body) => get(`customerInteraction${getSearchListQuery(body)}`),
    getNearbyBuildingData: (body) => get(`nearestBuildingdata${getSearchListQuery(body)}`),
    getMarketData: (body) => get(`marketregionselection${getSearchListQuery(body)}`),
    autocomplete: (body) => get(`autocomplete${getSearchListQuery(body)}`),
};
export default BuildingService;