import React from "react";
import PropTypes from "prop-types";
import { Button, Result } from "antd";

const NoMatch = ({ history }) => {
  return (
    <Result
      status="404"
      title="404"
      subTitle={"NoMatch"}
      extra={
        <Button type="primary" onClick={() => history.push("/properties")}>
          {"NoMatch"}
        </Button>
      }
    />
  );
};

NoMatch.propTypes = {
  history: PropTypes.object,
};

export default NoMatch;
