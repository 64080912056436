import ACTIONS from "./types.actions";
import visitsService from "../../services/visits.services";
import { to, throwError, toastMessage } from "../../utils/utils";

export function visitsData(data) {
    return { type: ACTIONS.GET_VISITS_DATA, data };
}

export function schedulerData(data) {
    return { type: ACTIONS.GET_SCHEDULED_DATA, data };
}

export function updatedCall(data) {
    return { type: ACTIONS.UPDATED_CALL, data };
}

export function setUpcomingData(data) {
    data.sort((a, b) => { return new Date(a.dateTime) - new Date(b.dateTime) });
    return { type: ACTIONS.GET_UPCOMING_DATA, data };
}

export function setPastData(data) {
    data.sort((a, b) => { return new Date(a.dateTime) - new Date(b.dateTime) });
    return { type: ACTIONS.GET_PAST_DATA, data };
}

export function getVisitsData(body) {
    return async dispatch => {
        try {
            dispatch(visitsData([]));
            body.limit = 100;
            const [err, response] = await to(visitsService.getVisitsData(body));
            if (err) throwError(err);
            if (response.message) dispatch(visitsData(response.message));
        } catch (error) {
            throwError(error);
        }
    };
}

export function getVisitsDetials(body) {
    return async dispatch => {
        try {
            const [err, response] = await to(visitsService.getVisit(body));
            if (err) throwError(err);
            dispatch(schedulerData(response.message.Items))
        } catch (error) {
            throwError(error);
        }
    };
}

export function createSchedule(body) {
    return async dispatch => {
        try {
            const [err, response] = await to(visitsService.createVisit(body));
            if (err) throwError(err);
            if (response.message.success) toastMessage("info", 'Scheduled!!');
            document.getElementById('plannerPop').click("toastr.info('Hi! I am info message.')");
        } catch (error) {
            throwError(error);
        }
    };
}

export function updateVisit(body) {
    return async dispatch => {
        try {
            dispatch(updatedCall(false))
            const [err, response] = await to(visitsService.updateVisit(body));
            if (err) throwError(err);
            if (response.message.success) toastMessage("info", 'Updated!!');
            document.getElementById('notesPop').click();
            dispatch(updatedCall(true))
        } catch (error) {
            throwError(error);
        }
    };
}

export function emailToCET(body) {
    return async dispatch => {
        try {
            const [err, response] = await to(visitsService.emailToCET(body));
            if (err) throwError(err);
            if (response.message.success) toastMessage("info", 'E-Mailled!!');
            document.getElementById('cetCanclePopup').click();
        } catch (error) {
            throwError(error);
        }
    };
}