import _isEmpty from 'lodash/isEmpty'
import { Tag } from 'antd'
import React from 'react'
import calendarIcon from '../assets/img/calendar.svg'
import sentimentIcon from '../assets/img/Sentiment.svg'
import completedIcon from '../assets/img/completed.svg'
import personIcon from '../assets/img/person.svg'
import info from '../assets/img/info.svg'
import mobile from '../assets/img/mobile.svg'
import phone from '../assets/img/phone.svg'
import mail from '../assets/img/mail.svg'

function Modal(props) {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={props.className}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.data.customer_experience_interaction_customer_name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ fontSize: '10px' }}
            ></button>
          </div>
          <div className="modal-body bg-fade">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="white-box pd-25 pop-border-radius">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <label className="title mb-10">Pitch Information</label>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="row">
                        <div className="col-7">
                          <div className="row">
                            <label className="pop-key">Subject</label>
                            <br></br>
                            <span className="pop-value">
                              {
                                props.data
                                  .customer_experience_interaction_subject_text
                              }
                            </span>
                            <p className="pop-key">
                              {
                                props.data
                                  .customer_experience_interaction_comments_text
                              }
                            </p>
                            <div className="col-3">
                              <div className="pop-box">
                                <img src={calendarIcon} alt="calendarIcon" />
                                <br></br>
                                <label className="pop-key">Visit Date</label>
                                <p className="pop-value">
                                  {
                                    props.data
                                      .customer_experience_interaction_created_date
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="pop-box">
                                <img src={personIcon} alt="personIcon" />
                                <br></br>
                                <label className="pop-key">Meeting Type</label>
                                <p className="pop-value">
                                  {
                                    props.data
                                      .customer_experience_interaction_method_category
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="pop-box">
                                <img src={completedIcon} alt="completedIcon" />
                                <br></br>
                                <label className="pop-key">Status</label>
                                <p className="pop-value">
                                  {
                                    props.data
                                      .customer_experience_interaction_status
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="pop-box">
                                <img src={sentimentIcon} alt="sentimentIcon" />
                                <br></br>
                                <label className="pop-key">Sentiment</label>
                                <p className="pop-value">
                                  {
                                    props.data
                                      .customer_experience_interaction_overall_sentiment_category
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="row">
                            <div className="col-6">
                              <label className="pop-key">
                                Open to Prologis Vendors: &nbsp;&nbsp;
                              </label>
                              <br></br>
                              <span className="pop-value">
                                {props.data.open_to_prologis_vendors}
                              </span>
                            </div>
                            <div className="col-6">
                              <label className="pop-key">
                                Automation: &nbsp;&nbsp;
                              </label>
                              <br></br>
                              <span className="pop-value">
                                {props.data.current_level_of_automation}
                              </span>
                            </div>
                            <div className="col-12">
                              <hr className="hr" />
                            </div>
                            <div className="col-12">
                              <label className="pop-key">Topics Covered</label>
                              <br></br>
                              {props.data.topics_discussed
                                ? props.data.topics_discussed
                                    .split(';')
                                    .map((i, e) => {
                                      return (
                                        <Tag color="#2C728A" key={`${e}-pills`}>
                                          {i}
                                        </Tag>
                                      )
                                    })
                                : ''}
                            </div>
                            <div className="col-12">
                              <label className="pop-key">
                                Types of products
                              </label>
                              <br></br>
                              {props.data.types_of_products_distributed
                                ? props.data.types_of_products_distributed
                                    .split(';')
                                    .map((i, e) => {
                                      return (
                                        <span
                                          className="pop-value pop-pill"
                                          key={`${e}-pills-2`}
                                        >
                                          {i}
                                        </span>
                                      )
                                    })
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-25">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="grid-col-left pop-border-radius">
                  <label className="pop-title">Challenges</label>
                  <div className="row">
                    <div className="col">
                      <label className="pop-key">
                        Primary Business Challenges
                      </label>
                      <p className="pop-value">
                        {props.data.primary_business_challenges}
                      </p>
                    </div>
                    <div className="col">
                      <label className="pop-key">Challenge in Space</label>
                      <p className="pop-value">
                        {props.data.current_challenges_in_space}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="pop-key">
                        Business Challenges Comments
                      </label>
                      <p className="pop-value">
                        {props.data.primary_business_challenges_comments}
                      </p>
                    </div>
                    <div className="col">
                      <label className="pop-key">
                        Challenge in Space Comments
                      </label>
                      <p className="pop-value">
                        {props.data.current_challenges_in_space_comments}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-sm-30">
                <div className="grid-col-right pop-border-radius">
                  <label className="pop-title">Customer Mailing Address</label>
                  <div className="row">
                    <div className="col">
                      <label className="pop-key">Customer Name</label>
                    </div>
                    <div className="col">
                      <label className="pop-value">
                        {
                          props.data
                            .customer_experience_interaction_customer_name
                        }
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="pop-key">Mailling Street</label>
                    </div>
                    <div className="col">
                      <label className="pop-value">
                        {props.data.who_mailingstreet}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="pop-key">Mailling City</label>
                    </div>
                    <div className="col">
                      <label className="pop-value">
                        {props.data.who_mailingcity}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="pop-key">Mailing Postal Code</label>
                    </div>
                    <div className="col">
                      <label className="pop-value">
                        {props.data.who_mailingpostalcode}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-25">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="grid-col-left pop-border-radius">
                  <label className="pop-title">
                    {props.data.customer_experience_interaction_customer_name}{' '}
                    Contact
                  </label>
                  <div className="row mt-10">
                    <div className="col-1">
                      <div className="pop-initials">
                        <img src={personIcon} alt="personIcon" width="50%" />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-6">
                              <label className="pop-value">
                                {props.data.who_full_name}
                              </label>
                              <p className="pop-key">{props.data.who_title}</p>
                            </div>
                            <div className="col-6">
                              <label className="pop-key">
                                Real Estate Decision maker?
                              </label>
                              <p className="pop-value"></p>
                            </div>
                            <div className="col-6">
                              <img src={mail} alt="personIcon" />
                              &nbsp;&nbsp;
                              <a href={`mailto:${props.data.who_email}`}>
                                <span className="text-normal color-blue bold underline font-12">
                                  {props.data.who_email}
                                </span>
                              </a>
                            </div>
                            <div className="col-6">
                              <label className="pop-key">Decision Role</label>
                              <p className="pop-value">{props.data.who_role}</p>
                              <hr className="hr" />
                            </div>
                            <div className="col-6">
                              <img src={phone} alt="personIcon" />
                              &nbsp;&nbsp;
                              <a href={`tel:${props.data.who_phone}`}>
                                <span className="text-normal color-blue bold underline font-12">
                                  {props.data.who_phone}
                                </span>
                              </a>
                            </div>
                            <div className="col-6">
                              <label className="pop-key">
                                Impact Capital/Ops Decision?
                              </label>
                              <p className="pop-value"></p>
                            </div>
                            <div className="col-6">
                              <img src={mobile} alt="personIcon" />
                              &nbsp;&nbsp;&nbsp;
                              <a href={`tel:${props.data.who_mobilephone}`}>
                                <span className="text-normal color-blue bold underline font-12">
                                  {props.data.who_mobilephone}
                                </span>
                              </a>
                            </div>
                            <div className="col-6">
                              <label className="pop-key">
                                Impact Capital/Ops Role
                              </label>
                              <p className="pop-value">
                                {props.data.capital_ops_decision_role__c}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 pop-nfo">
                          <img src={info} alt="personIcon" />
                          <label className="pop-value ml-10">
                            {props.data.who_full_name}
                          </label>
                          <p className="pop-key ml-30">
                            Easiest to reach him on mobile
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-sm-30">
                <div className="grid-col-right pop-border-radius">
                  <label className="pop-title">
                    Prologis Customer Experience Team
                  </label>
                  <div className="row mt-10">
                    <div className="col-2">
                      <div className="pop-initials">
                        <img src={personIcon} alt="personIcon" width="50%" />
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className="col-12">
                          <label className="pop-value">
                            {props.data.own_name}
                          </label>
                          <p className="pop-key">{props.data.own_title}</p>
                        </div>
                        <div className="col-12">
                          <img src={mail} alt="personIcon" />
                          &nbsp;&nbsp;
                          <a href={`mailto:${props.data.own_email}`}>
                            <span className="text-normal color-blue bold underline font-12">
                              {props.data.own_email}
                            </span>
                          </a>
                        </div>
                        <div className="col-12">
                          <img src={mobile} alt="personIcon" />
                          &nbsp;&nbsp;&nbsp;
                          <a href={`tel:${props.data.own_phone}`}>
                            <span className="text-normal color-blue bold underline font-12">
                              {props.data.own_phone}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {props.buttonSec}
            </button>
            {/* <button type="button" className="btn btn-primary">{props.buttonPrim}</button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
