import { produce } from "immer";
import ACTIONS from "../actions/types.actions";

const building = produce((draft, action) => {
    switch (action.type) {
        case ACTIONS.PITCH_TEMPLATE_DATA:
            draft.pitchTemplate = action.data;
            return draft;
        case ACTIONS.EMAIL_TEMPLATE_DATA:
            draft.emailTemplate = action.data;
            return draft;
        case ACTIONS.EMAIL_TEMPLATE_HTML:
            draft.templateData = action.data;
            return draft;
        case ACTIONS.EMAIL_STATUS:
            draft.emailStatus = action.data;
            return draft;
        default:
            return draft;
    }
});

export default building;