import _isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { useDispatch } from "react-redux";
import {
    updateVisit,
} from "../store/actions/visits.actions";

import nopath from '../assets/img/NoPath.png'

function SchedulerNotes(props) {

    const dispatch = useDispatch();
    const [data, setData] = useState({})
    const [notes, setNotes] = useState('')

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    const updateVisits = () => {
        let item = {
            "scheduleDateTime": new Date(data.scheduleDateTime).toISOString(),
            "meetingStartTime": new Date(data.meetingStartTime).toISOString(),
            "meetingEndTime": new Date(data.meetingEndTime).toISOString(),
            "customerName": data.customerName,
            "status": data.status,
            "customerContact": data.customerContact,
            "isSalesForceDone": data.isSalesForceDone,
            "isCancel": data.isCancel,
            "isMeetingComplete": data.isMeetingComplete,
            "buildingId": data.buildingId,
            "schedulerCoordinater": data.schedulerCoordinater,
            "tag": data.tag,
            "unitCode": data.unitCode,
            "isFollowUpDone": data.isFollowUpDone,
            "leaseId": data.leaseId,
            "schedulerName": data.schedulerName,
            "isMeetingConfirmed": data.isMeetingConfirmed,
            "customerId": data.customerId,
           
        }

        dispatch(updateVisit(item));
        setNotes('')
        document.getElementById('notesPop').click();
    }

    return (
        <div
            className="modal fade"
            id={props.id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className={props.className}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {props.title}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ fontSize: '10px' }}
                        ></button>
                    </div>
                    <div className="modal-body bg-fade">
                        {!_isEmpty(data) ?
                            <>
                                <div className="row">
                                    <div className="white-box pd-35">
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-sm-4">
                                            <img src={nopath} alt="ContactIcon" className="w-80" />
                                        </div>
                                        <div className="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-sm-8">
                                            <div className="row">
                                                <div className="col">
                                                    <label className="title">
                                                        {data.buildingName}
                                                        {'  '}
                                                        <Tag color="default">
                                                            {data.buildingId}
                                                        </Tag>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row pt-15">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="description copy">
                                                        <span id="address-block">
                                                            {data.buildingAddress}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row' >
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <label className="form-check-label pop-value">{data.customerName}</label>
                                                    <p className="form-check-label pop-key">{"Unit: "}{data.unitCode}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <form className="row g-3">
                                    <div className="col-12">
                                        <label className="pop-key">Visit Date</label>
                                        <input className="form-control" disabled value={new Date(data.scheduleDateTime).toDateString()} />
                                    </div>
                                    <div className="col-12">
                                        <label className="pop-key">Notes</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                                    </div>
                                </form>
                            </>
                            : ''}
                    </div>
                    <div className="modal-footer">
                        <button
                            id="notesPop"
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                props.clearSelection()
                            }}
                        >
                            {props.buttonSec}
                        </button>
                        <button type="button" className="btn btn-primary" onClick={updateVisits} >{props.buttonPrim}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchedulerNotes
