import { Button, Checkbox, Form, Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import {
  sendEmailAction,
  getEmailTemplates,
  getEmailTemplatesData,
  templateDataIn,
} from '../store/actions/pitch.actions'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { toastMessage } from '../utils/utils'
import mailTemp from '../assets/img/mailTemp.svg'
import blankTemp from '../assets/img/blankTemp.svg'

function Modal1(props) {
  const user = JSON.parse(localStorage.getItem('userInfo'))
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { emailTemplate, templateData } = useSelector((state) => state.pitch)
  
  const [select, setSelect] = useState('blank')

  useEffect(() => {
    dispatch(getEmailTemplates({ template_name: '' }))
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      subject: templateData.SubjectPart,
      message: !_isEmpty(templateData)
        ? templateData.HtmlPart.replace('{{signature}}', user.account.name)
        : '',
    })
  }, [templateData])

  const onFinish = (values) => {
    const body = {
      fullName: user.account.name,
      subject: values.subject,
      toEmail: values.customerEmail,
      body: values.message,
      fromEmail: user.account.username,
      cat: props.pitchList,
    }

    let bodyArr = []
    bodyArr.push(body)

    if (navigator.onLine) {
      dispatch(sendEmailAction(bodyArr))
      setTimeout(() => {
        form.resetFields()
        setSelect('blank')
        dispatch(templateDataIn([]))
      }, 1000)
    } else {
      if (!window.indexedDB) {
        return toastMessage(
          'info',
          "Your device doesn't support a stable version of IndexedDB. Offline Email feature will not be available.",
        )
      }

      ;(async () => {
        //...
        const request = indexedDB.open('esm')

        request.onupgradeneeded = (event) => {
          const db = event.target.result
          const objStore = db.createObjectStore('emails', {
            autoIncrement: true,
          })
        }

        request.onsuccess = (event) => {
          // Do something with request.result!
          const db = event.target.result
          const tx = db.transaction('emails', 'readwrite').objectStore('emails')
          tx.add(body)
          setTimeout(() => {
            form.resetFields()
          }, 1000)
          document
            .getElementById('cancel')
            .click("toastr.info('Hi! I am info message.')")
        }
      })()
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const getTemplateData = (e) => {
    if (e !== 'blank') {
      setSelect(e)
      dispatch(getEmailTemplatesData({ template_name: e }))
    } else {
      setSelect('blank')
      form.setFieldsValue({
        subject: '',
        message: '',
      })
      dispatch(templateDataIn([]))
    }
  }

  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={props.className}>
        <div className="modal-content">
          <div className="modal-header display-inline">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3">
                <h5 className="modal-title" id="exampleModalLabel">
                  CHOOSE TEMPLATE
                </h5>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 display-iflex">
                <h5 className="modal-title" id="exampleModalLabel">
                  {props.title}
                </h5>
                <button
                  type="button"
                  className="btn-close pd-15"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ fontSize: '10px' }}
                ></button>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 br ">
                <ul className="nav flex-column text-center">
                  {!_isEmpty(emailTemplate)
                    ? emailTemplate.map((e, i) => {
                        return (
                          <li
                            className={`nav-item pd-tb mb-10 cursor ${
                              select === e.Name ? 'templateSelect' : ''
                            }`}
                            key={`emailTemplates-${i}`}
                            onClick={() => getTemplateData(e.Name)}
                          >
                            <img src={mailTemp} alt="mailTemp" />
                            <br></br>
                            <label className="menu-lable bold">{e.Name}</label>
                          </li>
                        )
                      })
                    : ''}
                  <li
                    className={`nav-item pd-tb  cursor ${
                      select === 'blank' ? 'templateSelect' : ''
                    }`}
                    onClick={() => getTemplateData('blank')}
                  >
                    <img src={blankTemp} alt="mailTemp" />
                    <br></br>
                    <label className="menu-lable bold">{`Blank Template`}</label>
                  </li>
                </ul>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9">
                <Form
                  name="basic"
                  form={form}
                  labelCol={{
                    span: 8,
                  }}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email Id"
                    name="customerEmail"
                    rules={[
                      {
                        required: true,
                        message: 'Please provide Customer Email!',
                      },
                    ]}
                    className="form-label bold"
                  >
                    <Input
                      type="email"
                      className="form-control"
                      placeholder="jdoe@customername.com"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[
                      {
                        required: true,
                        message: 'Please provide Subject for Email!',
                      },
                    ]}
                    className="form-label bold"
                  >
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter Subject"
                    />
                  </Form.Item>

                  <Form.Item
                    name="message"
                    label="Message"
                    className="form-label bold"
                  >
                    <ReactQuill theme="snow" />
                  </Form.Item>

                  <div className="modal-footer block">
                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 16,
                      }}
                    >
                      <Button
                        type="primary"
                        className="btn btn-primary float-end"
                        htmlType="submit"
                      >
                        {props.buttonPrim}
                      </Button>

                      <Button
                        id="cancel"
                        type="button"
                        className="btn btn-secondary float-end mr-10"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setSelect('blank')
                          dispatch(templateDataIn([]))
                          form.setFieldsValue({
                            subject: '',
                            message: '',
                          })
                        }}
                      >
                        {props.buttonSec}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal1
