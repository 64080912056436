import _isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  getBuildingData,
  setRetainedValue,
  autoComplete,
  setAutoFill,
} from '../store/actions/buildings.actions'

function Search(props) {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  let { pathname } = useLocation()

  const { retainedValue, autoFillValue } = useSelector((state) => state.building)
  const [inputValue, setInputValue] = useState('')
  const [editDisplay, setEditDisplay] = useState('display-none')
  const [border, setBorder] = useState('')

  useEffect(() => {
    if (pathname === '/') {
      setInputValue(retainedValue)
      if (retainedValue.length > 0) {
        setEditDisplay('')
        setBorder('input-border')
      } else {
        setEditDisplay('display-none')
        setBorder('')
      }
    }
  }, [retainedValue])

  const onSearch = (e) => {
    if (e.key != 'Backspace' && e.key != 'Delete' && e.target.value.length > 0 && e.target.value.length % 3 === 0 )
      dispatch(autoComplete({ searchData: e.target.value }))

    if (e.key === 'Enter') {
      dispatch(setAutoFill([]))
      dispatch(getBuildingData({ searchData: e.target.value }))
      dispatch(setRetainedValue(e.target.value))
      if (props.callback) props.callback(e.target.value)
      if (props.page)
        navigate('/', { state: { key: true, searchVal: e.target.value } })
    }
  }

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const clearSearch = () => {
    setEditDisplay('display-none')
    setBorder('')
    dispatch(setRetainedValue(''))
  }

  return (
    <>
      <div className="input-container">
        <input
          id="searchBoxVal"
          className={`search-input search-query ${border}`}
          type="text"
          onKeyDown={onSearch}
          onChange={handleChange}
          list="datalistOptions"
          placeholder="Type building address, building name, customer name, property code to search"
          value={inputValue}
          autoComplete="off"
        />
        {!_isEmpty(autoFillValue) ?
          <datalist id="datalistOptions">
            {autoFillValue.map((e, i) => {
              console.log(e)
              return (<option value={e.name} key={`autocompletelable${i}`} />)
            })}
          </datalist>
          : ''}

        <div
          className={`clear-search ${editDisplay}`}
          onClick={clearSearch}
        ></div>
        <div className={`icon-search ${editDisplay}`}></div>

      </div>
    </>
  )
}

export default Search
