import { ConfigProvider } from "antd";
import React from "react";

import Routes from "./routes";

import './styles/Desktop.css';
import './styles/iPad.css';
import './styles/mobile.css';

function App() {
  return (
    <ConfigProvider>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
