import { notification } from 'antd';
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import SideMenu from '../../component/SideMenu';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Modal from '../../component/Modal1';

import {
    getPitchTemplateData,
} from "../../store/actions/pitch.actions";


const Pitch = ({ history, logout, loading }) => {

    const dispatch = useDispatch();
    const { pitchTemplate, emailStatus } = useSelector(state => state.pitch);
    const [catalogueList, setCatalogueList] = useState([]);

    useEffect(() => {
        dispatch(getPitchTemplateData());
    }, []);

    const select = (e) => {
        const cat = {
            id: e.id,
            link: e.catalog_link,
            name: e.name
        };
        if (catalogueList.some(i => i.id === cat.id)) {
            setCatalogueList(catalogueList.filter(i => i.id !== cat.id));
        } else {
            setCatalogueList(catalogueList => [...catalogueList, cat]);
        }
    };

    const clearSelection = () => {
        const nodelist = document.querySelectorAll("input");
        for (let i = 0; i < nodelist.length; i++) {
            nodelist[i].checked = false;
        }
        setCatalogueList([]);
    };

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className="sidenav">
                    <SideMenu
                        page="pitch"
                    />
                </div>
                <div id="pitch-main" className="main pb-100">
                    <div className="row">
                        <Header
                            title='Pitch Template'
                        />
                    </div>
                    <div className='border-top' style={{ height: `calc(100% - 5px)` }}>
                        <label className='catalogue-title lable-margin-top'>All Catalogue</label>

                        <div className="row container-margin-top">
                            {pitchTemplate.length > 0 ?
                                pitchTemplate.map((i, e) => {
                                    return (
                                        <div key={`catalogues-${e}`} className="col-lg-3 col-md-6 col-sm-6 col-xs-12 pb-10">
                                            <div className={`card ${i.catalog_link ? '' : 'disabled'}`}>
                                                <div>
                                                    <input type="checkbox" onClick={() => select(i)} className='card-checkbox' />
                                                    <Link to="/pitch-viewer" state={{ pdfLink: i.catalog_link, title: i.name }}>
                                                        <img src={i.image.url} className="card-img w-99" alt="ContactIcon" />
                                                    </Link>
                                                </div>
                                                <div className='card-div'>
                                                    <label className='title'>
                                                        {i.name}
                                                    </label>
                                                    <br></br>
                                                    <span className='sub-title'>
                                                        {i.sku}
                                                    </span>
                                                    <p className='card-para' dangerouslySetInnerHTML={{ __html: i.description.html }}>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : <label className="text-normal">No Data Available</label>}
                        </div>
                        {
                            catalogueList.length ?
                                <div className='selection-box' style={{ width: (document.getElementById("pitch-main").offsetWidth - 20) + "px" }}>
                                    <div className="user-initials">
                                        <label>{catalogueList.length}</label>
                                    </div>
                                    <span>Pitch Template Selected</span>
                                    <div className='button-container'>
                                        <button type="button" onClick={clearSelection} className="btn btn-outline-info float-right">Clear Selection</button>
                                        <button type="button" className="btn btn-light float-right" data-bs-toggle="modal" data-bs-target="#emailModal">Email Templates to Customer</button>
                                    </div>
                                </div>
                                : ''
                        }
                        <Modal
                            id="emailModal"
                            className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
                            title="ENTER CUSTOMER DETAILS"
                            buttonSec="Cancel"
                            buttonPrim="Send Email"
                            pitchList={catalogueList}
                        />
                    </div>
                </div>
                <Footer page="pitch" />
            </div>
        </React.Fragment>
    );
}

Pitch.propTypes = {
    history: PropTypes.object,
    loading: PropTypes.bool,
    logout: PropTypes.func,
};

export default Pitch;
