import React, { useEffect, useState } from "react"; // 
import { useSelector, useDispatch } from "react-redux"; // 
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { AuthService, signOut } from "../services/auth.service";
import history from "../utils/history.utils";
import routes from "./list.route";
import { checkAuth } from "../store/actions/user.actions";
import _isEmpty from "lodash/isEmpty";

function AppRoutes() {

  // const loading = useSelector(state => state.user.loading);
  const user = useSelector(state => state.user.value);
  const dispatch = useDispatch();

  useState(() => {
    dispatch(checkAuth());
  });

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        // console.log("Browser tab is hidden");
      } else {
        const [currentAccount = null] = AuthService.getAllAccounts();

        if (!currentAccount && !window.location.href.includes("/auth/callback")) {
          setTimeout(() => {
            signOut();
          }, 3000);
        }
      }
    });
  }, []);

  return (
    <BrowserRouter history={history}>
      {!_isEmpty(user) ? (
        <Routes>
          {routes &&
            routes.map(({ component, componentProps, path, exact, category, action }) => (
              <Route
                exact={exact}
                key={`${path}-route`}
                path={`${path}`}
                element={component}
              />
            ))}
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      ) : ''}
    </BrowserRouter>
  );
}

export default AppRoutes;
