/* eslint-disable no-restricted-globals */
import axios from "axios";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { BASE_URL } from "../utils/constants";
import { toastMessage } from "../utils/utils";
import { AuthService, signIn } from "./auth.service";

export function SetApiRequestHeader(customHeader = {}) {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...customHeader,
  };
  return defaultHeaders;
}

const instance = axios.create({
  baseURL: BASE_URL || "/api/",
  timeout: 29000,
  headers: SetApiRequestHeader(),
});

instance.interceptors.request.use(
  config => {
    let tokenResponse = JSON.parse(localStorage.getItem('userInfo'));

    if (tokenResponse) {
      config.headers.Authorization = tokenResponse.accessToken
      return Promise.resolve(config);
    }
    // const [currentAccount = null] = AuthService.getAllAccounts();
    // if (!_isEmpty(currentAccount)) {
    //   const request = {
    //     scopes: ["openid"],
    //     account: currentAccount,
    //     forceRefresh: false,
    //   };
    //   return AuthService.acquireTokenSilent(request)
    //     .then(tokenResponse => {
    //       config.headers.Authorization = `${tokenResponse.accessToken}`; // Bearer
    //       return Promise.resolve(config);
    //     })
    //     .catch(error => {
    //       console.log("sign in error: ==>", error);

    //       toastMessage("info", "Your session is expired we are trying to refresh your session.");
    //       return AuthService.acquireTokenRedirect({ loginHint: currentAccount.username, state: location.pathname });
    //     });
    // }

    toastMessage("info", "Your session is expired we are redirecting you to login screen.");
    setTimeout(() => {
      signIn({ state: location.pathname });
    }, 1000);
    return config;
  },
  err => Promise.reject(err),
);

instance.interceptors.response.use(undefined, err => {
  const { config } = err;

  if (!config || !config.retry) return Promise.reject(err);

  config.__retryCount = config.__retryCount || 0;
  if (config.__retryCount >= config.retry) {
    return Promise.reject(err);
  }

  config.__retryCount += 1;
  const backoff = new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, config.retryDelay || 1);
  });

  return backoff.then(() => axios(config));
});

// Add a response interceptor
instance.interceptors.response.use(
  response =>
    response.data,
  error => {
    if (error.response) {
      if (!_isEmpty(error, "response") && error.response.status >= 400) {
        const errorObj =
          typeof error === "string"
            ? error
            : _get(error, "response.data.message") || _get(error, "response.data.error") || "Something Went Wrong";
        const errMessage = decodeURI(errorObj.message || errorObj);
        toastMessage("error", errMessage);
        return Promise.reject(errMessage);
      }
    }

    const err = error.message ? error.message : JSON.stringify(error);
    console.log("error===> ", error);
    return Promise.reject(err);
  },
);

const _getApiVersion = (params = {}) => params.apiVersion || "";
const _retryParams = (params = {}) => ({
  ...params,
  validateStatus: status => status < 400,
  retry: 4,
  retryDelay: 2000,
});

export function get(url, params) {
  return instance.get(`${_getApiVersion(params)}/${url}`, _retryParams(params));
}

export function post(url, body, params) {
  return instance.post(`${_getApiVersion(params)}/${url}`, body, _retryParams(params));
}

export function put(url, body, params) {
  return instance.put(`${_getApiVersion(params)}/${url}`, body, _retryParams(params));
}

export function patch(url, params, body) {
  return instance.patch(`${_getApiVersion(params)}/${url}`, body || {}, _retryParams(params));
}

export function remove(url, params, body) {
  return instance.delete(`${_getApiVersion(params)}/${url}`, body || {}, _retryParams(params));
}
